import { postFormToUrl } from 'application/adapters/cms/formAdapter';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Modal, SignupForm } from 'ui/components';

export const ModalSignupFormFeature: React.FC<Umbraco.ModalSignupForm> = ({
	content,
	settings,
	accessToken,
	culture,
	host,
}) => {
	const router = useRouter();
	const [isLoading, setIsLoading] = useState(false);
	const [formIsSent, setFormIsSent] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const dictionary = useContext(DictionaryContext);
	const reCaptchaApiKey = '6LdnLqgUAAAAAOC1Gp8qi-cEl-inU-ZKO_XwFRbZ';
	const userBrowserLocale = Intl.DateTimeFormat().resolvedOptions().locale;

	const SignupFormLinkText = dictionary.getValue(
		'SignupForm.LinkText',
		null,
		'Request Account',
	);

	const regex = /\s/gi;
	const signupFormLinkTextNormalized = encodeURIComponent(
		SignupFormLinkText.replace(regex, ''),
	);

	const validHashes = [
		'RequestAccount',
		'requestaccount',
		signupFormLinkTextNormalized,
		signupFormLinkTextNormalized.toLowerCase(),
		signupFormLinkTextNormalized.toUpperCase(),
	];

	const handleClose = () => {
		setIsOpen(false);
		setFormIsSent(false);

		if (validHashes.includes(location.hash.replace('#', ''))) {
			history.replaceState(
				'',
				'',
				window.location.pathname + window.location.search,
			);
		}
	};

	const onSubmit = async (data) => {
		setIsLoading(true);
		const ok = await postFormToUrl(
			'Umbraco/Api/Forms/SignUp',
			data,
			accessToken,
			culture,
			host,
		);

		if (ok) {
			setFormIsSent(true);
			setIsLoading(false);
		} else {
			setIsLoading(false);
		}
	};

	// Trigger modal on hashchange to valid hashname.
	//
	// To make sure that any link on the site can trigger the modal (including links that are not built with
	// the Next Link component), we listen for both the Next router "hashChangeComplete" event and
	// the standard JavaScript "hashchange" event.
	useEffect(() => {
		const onHashChange = (url: any) => {
			// For Next router events, the 'url' parameter will be a simple string, while the standard
			// JavaScript "hashchange" event will return 'url' as an event object on which we can access the
			// url via the 'newURL' property.
			let hash: string;

			if (typeof url === 'object') {
				hash = url.newURL
					? url.newURL.split('#')[1]
					: location.hash.replace('#', '');
			} else {
				hash = url.split('#')[1];
			}

			validHashes.includes(hash) ? setIsOpen(true) : setIsOpen(false);
		};

		router.events.on('hashChangeComplete', onHashChange);
		window.addEventListener('hashchange', onHashChange);

		// Trigger modal on initial load if URL contains valid hash
		window.addEventListener('load', onHashChange);

		return () => {
			// Remove events if component is unmounted
			router.events.off('hashChangeComplete', onHashChange);
			window.removeEventListener('hashchange', onHashChange);
			window.removeEventListener('load', onHashChange);
		};
	}, [router.locale]);

	const signupFormDictionary = {
		formErrorMessage: dictionary.getValue(
			'Form.ErrorMessage',
			null,
			'This field is required',
		),
		formEmail: dictionary.getValue('Form.Email', null, 'Email'),
		formFirstName: dictionary.getValue(
			'Form.FirstName',
			null,
			'First name',
		),
		formLastName: dictionary.getValue('Form.LastName', null, 'Last name'),
		formPhoneNumber: dictionary.getValue(
			'Form.PhoneNumber',
			null,
			'Phone number',
		),
		formFaxNumber: dictionary.getValue(
			'Form.FaxNumber',
			null,
			'Fax no. (optional)',
		),
		formCompanyName: dictionary.getValue(
			'Form.CompanyName',
			null,
			'Company Name',
		),
		formAddress: dictionary.getValue('Form.Address', null, 'Address'),
		formAddressLine2: dictionary.getValue(
			'Form.AddressLine2',
			null,
			'Address line 2 (optional)',
		),
		formCity: dictionary.getValue('Form.City', null, 'City'),
		formPostalCode: dictionary.getValue(
			'Form.PostalCode',
			null,
			'Postal Code',
		),
		formState: dictionary.getValue('Form.State', null, 'State'),
		formCountry: dictionary.getValue('Form.Country', null, 'Country'),
		formCountrySelect: dictionary.getValue(
			'Form.CountrySelect',
			null,
			'Choose a country',
		),
		formShopRole: dictionary.getValue(
			'Form.ShopRole',
			null,
			'Request a role',
		),
		formShopRoleSelect: dictionary.getValue(
			'Form.ShopRoleSelect',
			null,
			'Choose role',
		),
		formShopRoleBusinessPartner: dictionary.getValue(
			'Form.ShopRoleBusinessPartner',
			null,
			'Business partner',
		),
		formShopRoleReseller: dictionary.getValue(
			'Form.ShopRoleReseller',
			null,
			'Reseller',
		),
		formInvoiceEmailAddress: dictionary.getValue(
			'Form.InvoiceEmailAddress',
			null,
			'Email to receive Invoices',
		),
		formSend: dictionary.getValue('Form.Send', null, 'Send'),
		formThankYou: dictionary.getValue('Form.ThankYou', null, 'Send'),
		formCaptcha: dictionary.getValue(
			'Form.Captcha',
			null,
			'Please insert the characters in the picture above',
		),
		formCaptchaInvalid: dictionary.getValue(
			'Form.CaptchaInvalid',
			null,
			'Characters input doesnt match the picture',
		),
		formCaptchaReload: dictionary.getValue(
			'Form.CaptchaReload',
			null,
			'Reload picture',
		),
	};

	return (
		<>
			{isOpen && (
				<Modal
					modalType="formModal"
					handleClose={handleClose}
					title={formIsSent ? '' : content?.properties?.title}
				>
					<SignupForm
						dictionary={signupFormDictionary}
						onFormSubmit={onSubmit}
						{...content?.properties}
						{...settings?.properties}
						isLoading={isLoading}
						formIsSent={formIsSent}
						reCaptchaApiKey={reCaptchaApiKey}
						userBrowserLocale={userBrowserLocale}
					/>
				</Modal>
			)}
		</>
	);
};

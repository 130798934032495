import { Container } from 'ui/components';
import classNames from 'classnames';
import styles from './ContentSection.module.scss';

export interface ContentSectionProps {
	width: Models.SectionWidth;
	title: string;
	centered?: boolean;
}

export const ContentSection: React.FC<ContentSectionProps> = ({
	children,
	width,
	centered,
	title,
}) => {
	return (
		<section
			aria-label={title}
			className={classNames(styles.Section, styles.Section___margined, {
				[styles.Section___centered]: centered,
			})}
		>
			<Container width={width}>
				<div className={styles.Section_content}>
					<div className="u-grid u-grid--wrap u-grid--stretch u-grid--row-gap u-grid--container">
						{children}
					</div>
				</div>
			</Container>
		</section>
	);
};

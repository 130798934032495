import { ProductsInCart } from 'ui/components/3-organisms/ProductsInCart';
import {
	selectCartProducts,
	setCart,
	currentCartId,
	costDetails,
	currency,
	total,
} from 'application/adapters/store/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { updateBasket } from 'application/repositories/ecommerceRepository';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import logger from 'helpers/logger';
import { CheckoutDictionary as checkoutDictionaryFunc } from '../_checkoutDictionary';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import { EcommerceApiError } from 'helpers/error';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { deleteUserBasket } from 'application/repositories/ecommerceRepository';
import { resetCart } from 'application/adapters/store/slices/cartSlice';
import formatCartPrices from 'helpers/formatCartPrices';
import formatProductPrices from 'helpers/formatProductPrices';

export const BasketPageFeature: React.FC<Umbraco.BasketPage> = ({
	content,
	accessToken,
}) => {
	const checkoutDictionary = checkoutDictionaryFunc();

	const router = useRouter();
	const businessPartner = useSelector(currentCustomer);
	const bpId = businessPartner?.id;
	let proceedLink = content?.properties?.proceedLink?.url;

	useEffect(() => {
		if (proceedLink) {
			const url = content.properties.proceedLink.url;
			if (url.includes(window?.location?.origin)) {
				proceedLink = url.replace(window.location.origin, '');
			}
		}
	}, []);

	const selectedCartProducts = useSelector(selectCartProducts);
	const basketId = useSelector(currentCartId);
	const dispatch = useDispatch();
	const cDetails = useSelector(costDetails);
	const cTotal = useSelector(total);
	const currencyCode = useSelector(currency);
	const culture = useSelector(currentCulture);
	const localeContext = useContext(LocaleContext);
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [quickOrderError, setQuickOrderError] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<EcommerceApiError[]>([]);

	const productUpdated = (product, delta) => {
		logger.info('productUpdated');
		setQuickOrderError('');
		setErrors([]);
		setIsProcessing(true);

		const cartProduct: Models.UpdateBasketProduct = {
			basketId: basketId,
			quantityDelta: delta,
			sku: product.sku,
			cultureCode: culture,
			bpId: bpId,
		};

		return updateBasket(accessToken, cartProduct, true)
			.then((res) => {
				dispatch(
					setCart(formatCartPrices(res, localeContext, currencyCode)),
				);
				setIsProcessing(false);
				return Promise.resolve(res);
			})
			.catch((error) => {
				const errorsArray = [];
				let hasQuickOrderError = false;

				error.map((error) => {
					// UpstreamServiceUnavailable (409) - Http: 503 (Service Unavailable)
					if (error.code === 409) {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaUpstreamServiceUnavailable;
						const dictionaryMessage =
							checkoutDictionary.errorMessageProductNotFound;
						errorsArray.push({
							area: dictionaryArea,
							message: dictionaryMessage,
						});
					}

					// LNUnavailable (400) - Http: 503 (ServiceUnavailable)
					if (error.code === 400) {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaExternalError;
						const dictionaryMessage =
							checkoutDictionary.errorMessageLNUnavailable;
						errorsArray.push({
							area: dictionaryArea,
							message: dictionaryMessage,
						});
					}

					// ProductNotFound (600) - Http: 404 (Not Found)
					if (error.code === 600) {
						hasQuickOrderError = true;
						const dictionaryMessage =
							checkoutDictionary.errorMessageProductNotFound;
						setQuickOrderError(dictionaryMessage);
						return Promise.resolve(error);
					}
					
					if (error.code === 403) {	
						hasQuickOrderError = true;
						
						// ProductNotFoundInLN (403) - Http: 404 (Not Found)
						if (error.name === 'ProductNotFoundInLN') {	
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotFoundInLn;
							const sku = error.subjectId; // Do not delete, is actually used
							setQuickOrderError(eval('`' + dictionaryMessage + '`'));

							return Promise.resolve(error);
						}

						// ProductDiscontinuedInLN (403) - Http: 404 (Not Found)
						if (error.name === 'ProductDiscontinuedInLN') {
							const dictionaryMessage =
							checkoutDictionary.errorMessageProductIsDiscontinued;
							setQuickOrderError(dictionaryMessage);

							return Promise.resolve(error);
						}
					}

					// ProductNotAvailableForBuyer (601) - Http: 400 (Bad Request)
					if (error.code === 601) {
						hasQuickOrderError = true;
						const dictionaryMessage =
							checkoutDictionary.errorMessageProductNotAvailableForBuyer;
						setQuickOrderError(dictionaryMessage);
						return Promise.resolve(error);
					}

					// ProductNotForSaleInLN (405) - Http: 404 (Not Found)
					if (error.code === 405) {
						hasQuickOrderError = true;
						const dictionaryMessage =
							checkoutDictionary.errorMessageProductNotForSaleInLN;
						setQuickOrderError(dictionaryMessage);
						return Promise.resolve(error);
					}

					// BasketItemInvalidInLN (408) - Http: 404 (Not Found)
					if (error.code === 408) {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaExternalError;
						const dictionaryMessage =
							checkoutDictionary.errorMessageBasketItemInvalidInLN;
						const sku = error.subjectId; // Do not delete, is actually used
						const bpId = cartProduct.bpId; // Do not delete, is actually used
						errorsArray.push({
							area: dictionaryArea,
							message: eval('`' + dictionaryMessage + '`'),
						});
					}

					// ProductMissingFromBasket (103) - Http: 404 (Not found)
					if (error.code === 103) {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaBasket;
						const dictionaryMessage =
							checkoutDictionary.errorMessageProductMissingFromBasket;
						const itemId = error.subjectId; // Do not delete, is actually used
						const bpId = cartProduct.bpId; // Do not delete, is actually used
						// basketId is already defined in start of compontent
						errorsArray.push({
							area: dictionaryArea,
							message: eval('`' + dictionaryMessage + '`'),
						});
					}

					// ServerError (900) - Http: 500 (InternalServerError)
					if (error.code === 900) {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaUnidentified;
						const dictionaryMessage =
							checkoutDictionary.errorMessageServerError;
						const bpId = cartProduct.bpId; // Do not delete, is actually used
						// basketId is already defined in start of compontent
						errorsArray.push({
							area: dictionaryArea,
							message: eval('`' + dictionaryMessage + '`'),
						});
					}
				});

				if (errorsArray.length !== 0 || hasQuickOrderError !== false) {
					setErrors(errorsArray);
				} else {
					const dictionaryArea =
						checkoutDictionary.errorMessageAreaExternalError;
					const dictionaryMessage =
						checkoutDictionary.errorMessageUnexpectedError;
					errorsArray.push({
						area: dictionaryArea,
						message: dictionaryMessage,
					});
					setErrors(errorsArray);
				}

				setIsProcessing(false);
				return Promise.resolve(error);
			});
	};

	const handleProceed = () => {
		if (proceedLink) {
			router.push(proceedLink);
		}
	};

	const ourProductsLink = '/our-products/';

	const clearBasket = async () => {
		setIsLoading(true);
		await deleteUserBasket(bpId);
		dispatch(resetCart());
		setIsLoading(false);
	};

	return (
		<>
			{isLoading && <PageSpinner />}
			<ProductsInCart
				products={selectedCartProducts.map((p) =>
					formatProductPrices(p, localeContext, currencyCode),
				)}
				costDetails={{
					costs: cDetails,
					total: {
						...cTotal,
						formattedTotal: localeContext.formatPrice(
							cTotal.totalPrice,
							currencyCode,
						),
					},
				}}
				isBasketView={true}
				productUpdated={productUpdated}
				handleProceed={handleProceed}
				currency={currencyCode}
				isProcessing={isProcessing}
				quickOrderError={quickOrderError}
				dictionary={checkoutDictionary}
				ourProductsLink={ourProductsLink}
				accessToken={accessToken}
				setIsLoading={setIsLoading}
				errors={errors}
				clearBasket={clearBasket}
			/>
		</>
	);
};

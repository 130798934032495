import classNames from 'classnames';
import styles from './Promotion.module.scss';
import { ReactComponent as StripesIcon } from 'ui/icons/icon-stripes.svg';
import {
	Icon,
	Heading,
	Subheading,
	Picture,
	LinkButton,
	Link,
} from 'ui/components';
//import { ImageProps } from 'ui/components/1-atoms/Media/Image/Image';
import { Container } from 'ui/components';

export interface PromotionProps {
	className?: string;
	heading: string;
	subHeading: string;
	lead: string;
	subLead: string;
	buttonText: string;
	link?: Umbraco.Link;
	image?: Umbraco.Image;
}

export const Promotion: React.FC<PromotionProps> = ({
	className,
	heading,
	subHeading,
	lead,
	subLead,
	buttonText,
	link,
	image,
}) => (
	<Container width="Standard">
		<article className={classNames(styles.Promotion, className)}>
			<div className={styles.Promotion_imageContainer}>
				{image && (
					<Link url={link.url}>
						<Picture
							url={image.url}
							properties={image.properties}
							sizes="100vw"
							className={styles.Promotion_mediabox}
						/>
					</Link>
				)}
			</div>
			<section className={styles.Promotion_infoContainer}>
				<Icon size="xl" className={styles.Promotion_icon}>
					<StripesIcon />
				</Icon>
				<Heading
					headingLevel="h2"
					style="lg"
					className={styles.Promotion_heading}
				>
					{heading}
				</Heading>
				<Subheading className={styles.Promotion_subHeading}>
					{subHeading}
				</Subheading>
				<p className={styles.Promotion_lead}>{lead}</p>
				<p className={styles.Promotion_subLead}>{subLead}</p>
				<LinkButton
					className={styles.ProductDetails_submitButton}
					style="primary"
					url={link?.url}
				>
					{buttonText}
				</LinkButton>
			</section>
		</article>
	</Container>
);

import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { checkoutNavigationSteps } from 'application/repositories/checkoutRepository';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { fontPath } from 'helpers/fonts';
import { StepNavigation, Payment, Container, Spinner } from 'ui/components';
import { Appearance, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { createSetup } from 'application/repositories/ecommerceRepository';
import { useDispatch, useSelector } from 'react-redux';
import {
	currentCartId,
	total,
	currency,
} from '../../../application/adapters/store/slices/cartSlice';
import { CheckoutForm } from 'ui/components';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import {
	currentOrder,
	setCurrentOrder,
} from 'application/adapters/store/slices/orderSlice';

export const PaymentPageFeature: React.FC<Umbraco.PaymentPage> = ({
	content,
	accessToken,
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const router = useRouter();
	const basketId = useSelector(currentCartId);
	const dictionary = useContext(DictionaryContext);
	const steps: Models.NavigationStep[] = checkoutNavigationSteps(dictionary);
	const [isWaitingForQuery, setisWaitingForQuery] = useState(true);
	const businessPartner = useSelector(currentCustomer);
	const [setupIntent, setSetupIntent] = useState<string>(
		router.query['setup_intent']?.toString(),
	);
	const [redirectStatus, setRedirectStatus] = useState<string>(
		router.query['redirect_status']?.toString(),
	);
	const dispatch = useDispatch();
	const orderId = useSelector(currentOrder);
	const cTotal = useSelector(total).orderTotal;
	const cCurrency = useSelector(currency);

	// Reroute if a successful payment has been received
	useEffect(() => {
		if (setupIntent && redirectStatus === 'succeeded') {
			router.push(
				`${content.properties.proceedLink.url}?orderId=${orderId}`,
			);
		} else {
			setisWaitingForQuery(false);
		}
	}, []);

	// Stripe
	const [stripePromise, setStripePromise] = useState(null);
	const [clientSecret, setClientSecret] = useState('');

	useEffect(() => {
		if (!setupIntent && redirectStatus != 'succeeded') {
			createSetup(accessToken, businessPartner?.id, basketId)
				.then((response) => {
					setStripePromise(loadStripe(response.publishableKey));
					setClientSecret(response.clientSecret);
					dispatch(setCurrentOrder(response.orderGuid));
					setIsLoading(false);
				})
				.catch(() => setIsLoading(false));
		}
	}, [businessPartner?.id]);

	const handlePrevious = () => {
		router.push(content.properties.previousLink.url);
	};

	const appearance: Appearance = {
		theme: 'stripe',
		variables: {
			fontFamily: '"IBM Plex Sans", sans-serif',
			colorTextPlaceholder: '#bdbdbd',
		},
		rules: {
			'.Label': {
				margin: '0 0 5px',
				color: '#393939',
				fontWeight: '600',
			},
			'.Input': {
				border: '1px solid #bdbdbd',
				borderRadius: '4px',
			},
		},
	};

	const options = {
		clientSecret,
		appearance,
		fonts: [
			{
				cssSrc:
					typeof window !== 'undefined'
						? window.location.origin + fontPath
						: null,
			},
		],
	};

	const stripeStatusMessages = {
		succeeded: dictionary.getValue(
			'Checkout.Payment.Success',
			null,
			'Payment succeeded!',
		),
		processing: dictionary.getValue(
			'Checkout.Payment.Processing',
			null,
			'Your payment is processing.',
		),
		requiresPaymentMethod: dictionary.getValue(
			'Checkout.Payment.RequiresPaymentMethod',
			null,
			'Your payment was not successful, please try again.',
		),
		failed: dictionary.getValue(
			'Checkout.Payment.Failed',
			null,
			'Something went wrong.',
		),
	};

	if (isWaitingForQuery) return null;

	return (
		<>
			<StepNavigation
				steps={steps}
				handlePrevious={handlePrevious}
				isProceedDisabled={true}
				currentStep={2}
				heading={dictionary.getValue(
					'Checkout.Heading',
					null,
					'Checkout',
				)}
			/>
			{isLoading && (
				<Container width="Standard">
					<div
						style={{
							textAlign: 'center',
							marginBottom: 'var(--spacing--sm)',
						}}
					>
						<Spinner />
					</div>
					<p
						style={{
							textAlign: 'center',
							marginBottom: 'var(--spacing--lg)',
						}}
					>
						{!setupIntent &&
							redirectStatus != 'succeeded' &&
							dictionary.getValue(
								'Checkout.WaitingMessageCreditCard',
								null,
								'Please wait while payment options is loading.',
							)}

						{setupIntent &&
							redirectStatus === 'succeeded' &&
							dictionary.getValue(
								'Checkout.WaitingMessageInvoice',
								null,
								'Please wait a moment.',
							)}
					</p>
				</Container>
			)}
			<Payment className="u-bottom-margin--xxxl">
				{stripePromise && clientSecret && (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm
							heading={dictionary.getValue(
								'Checkout.Payment.Heading',
								null,
								'Please enter your payment details below:',
							)}
							isButtonDisabled={false}
							buttonText={dictionary.getValue(
								'Checkout.Payment.PayButtonText',
								null,
								'Pay now',
							)}
							statusMessages={stripeStatusMessages}
							amount={cTotal}
							currency={cCurrency}
						/>
					</Elements>
				)}
			</Payment>
		</>
	);
};

import { getBusinessPartnersFromAdapter } from 'application/adapters/ecommerce/businessPartnersAdapter';

export const getBusinessPartners = async (accessToken: string) => {
	if (!accessToken) return null;

	const businessPartners = await getBusinessPartnersFromAdapter(accessToken);

	if (!businessPartners) return null;

	const mappedBusinessPartners = businessPartners
		?.filter(
			(businessPartner) =>
				(businessPartner?.name || businessPartner?.information?.name) &&
				businessPartner?.metadata?.addresses,
		)
		.map((businessPartner) => {
			return {
				...businessPartner,
				name:
					businessPartner?.name ??
					businessPartner?.information?.name ??
					'',
				addresses: businessPartner?.metadata?.addresses ?? [],
			};
		});

	return mappedBusinessPartners ?? null;
};

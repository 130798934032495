import { useContext, useEffect } from 'react';
import { CategoryFilter } from 'ui/components/3-organisms/CategoryFilter';
import { getCategoryById } from 'application/repositories/ecommerceRepository';
import {
	setCategories,
	categories,
	setSelectedCategory,
	selection,
} from 'application/adapters/store/slices/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { useRouter } from 'next/router';

export const CategoryFilterFeature: React.FC<Umbraco.CategoryFilter> = ({
	content,
	accessToken,
}) => {
	const dispatch = useDispatch();
	const dictionary = useContext(DictionaryContext);
	const { categoryId } = content?.properties ?? {};

	const categoryData = useSelector(categories);
	const selectionData = useSelector(selection);
	const culture = useSelector(currentCulture);
	const businessPartner = useSelector(currentCustomer);

	const fetchCategory = async () => {
		await getCategoryById(
			categoryId,
			accessToken,
			culture,
			businessPartner?.id ?? '',
		).then((res) => {
			if (res.categoryFilterItems) {
				const defaultCategory = dictionary.getValue(
					'Category.ViewAll',
					null,
					'View All',
				);
				res.categoryFilterItems.unshift({
					children: defaultCategory,
					categoryNumber: res.categoryNumber,
					id: res.id,
				});
				res.chosenSortingParameter = defaultCategory;
			}
			dispatch(setCategories(res));

			const hasSubCategory = res.categoryFilterItems.find(
				(categoryFilterItem: Models.CategoryFilterItem) =>
					categoryFilterItem.children === router.query.subCategory,
			);

			if (!hasSubCategory) {
				dispatch(
					setSelectedCategory({
						categoryNumber: res.categoryNumber,
						children:
							res.categoryFilterItems?.length > 0
								? res.categoryFilterItems[0].children
								: res.heading,
						id: res.id,
					}),
				);
			} else {
				dispatch(setSelectedCategory(hasSubCategory));
			}
		});
	};

	const router = useRouter();
	const scrollToView = router.query.subCategory ? true : false;

	const scrollIntoProductView = () => {
		if (screen.width > 640) {
			const element = document.getElementById('productList');
			const headerOffset = 300;
			const elementPosition = element.getBoundingClientRect().top;
			const offsetPosition =
				elementPosition + window.pageYOffset - headerOffset;

			window.scrollTo({
				top: offsetPosition,
				behavior: 'smooth',
			});
		}
	};

	function onSelectionChanged(item: Models.CategoryFilterItem) {
		dispatch(setSelectedCategory(item));
		if (item.children !== 'View All') {
			router.replace(
				{
					pathname: `/our-products/${content.properties.categoryId.toLowerCase()}`,
					query: { subCategory: item.children },
				},
				undefined,
				{ scroll: false },
			);
		} else {
			router.replace(
				{
					pathname: `/our-products/${content.properties.categoryId.toLowerCase()}`,
				},
				undefined,
				{ scroll: false },
			);
		}
	}

	useEffect(() => {
		fetchCategory();
	}, [categoryId, content, businessPartner?.id]);

	// Observer for when an element exists
	const waitForElm = (selector) => {
		return new Promise((resolve) => {
			if (document.querySelector(selector)) {
				return resolve(document.querySelector(selector));
			}

			const observer = new MutationObserver((mutations) => {
				if (document.querySelector(selector)) {
					observer.disconnect();
					resolve(document.querySelector(selector));
				}
			});

			observer.observe(document.body, {
				childList: true,
				subtree: true,
			});
		});
	};

	useEffect(() => {
		// Await when productList exists before scrolling down
		waitForElm('#productList').then(() => {
			scrollToView && scrollIntoProductView();
		});
	}, []);

	return (
		<>
			{categoryData && (
				<CategoryFilter
					{...(categoryData as any)}
					sortingParameter={
						router.query?.subCategory
							? router.query?.subCategory
							: selectionData?.children
					}
					handleSelectionChanged={onSelectionChanged}
					scrollIntoProductView={scrollIntoProductView}
				/>
			)}
		</>
	);
};

import { Slider } from 'ui/components/3-organisms/Slider';
import { SliderItemProps } from 'ui/components/3-organisms/Slider/SliderItem/SliderItem';

export const SliderFeature: React.FC<Umbraco.Slider> = ({ content }) => {
	const { slides } = content?.properties ?? {};

	const mappedSlides: SliderItemProps[] = slides.map(({ content }) => {
		const { heading, text } = content?.properties ?? {};

		return {
			heading,
			text,
		};
	});

	return <Slider slides={mappedSlides} />;
};

import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Modal, HelpForm } from 'ui/components';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { postFormToUrl } from 'application/adapters/cms/formAdapter';

export const ModalHelpFormFeature: React.FC<Umbraco.ModalHelpForm> = ({
	content,
	settings,
	accessToken,
	culture,
	host,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const router = useRouter();
	const dictionary = useContext(DictionaryContext);
	const HelpFormLinkText = dictionary.getValue(
		'ContactForm.LinkText',
		null,
		'Contact',
	);
	const validHashes = [
		'Contact',
		'contact',
		encodeURIComponent(HelpFormLinkText.replace(' ', '')),
	];

	const handleClose = () => {
		setIsOpen(false);

		if (validHashes.includes(location.hash.replace('#', ''))) {
			history.replaceState(
				'',
				'',
				window.location.pathname + window.location.search,
			);
		}
	};

	useEffect(() => {
		const onHashChange = (url: any) => {
			let hash: string;

			if (typeof url === 'object') {
				hash = url.newURL
					? url.newURL.split('#')[1]
					: location.hash.replace('#', '');
			} else {
				hash = url.split('#')[1];
			}

			validHashes.includes(hash) ? setIsOpen(true) : setIsOpen(false);
		};

		router.events.on('hashChangeComplete', onHashChange);
		window.addEventListener('hashchange', onHashChange);
		window.addEventListener('load', onHashChange);

		return () => {
			router.events.off('hashChangeComplete', onHashChange);
			window.removeEventListener('hashchange', onHashChange);
			window.removeEventListener('load', onHashChange);
		};
	}, []);

	const onSubmit = async (data) => {
		const ok = await postFormToUrl(
			'Umbraco/Api/Forms/Contact',
			data,
			accessToken,
			culture,
			host,
		);

		if (ok) {
			handleClose();
		}
	};

	return (
		<>
			{isOpen && (
				<Modal
					title={content?.properties.title}
					modalType="formModal"
					handleClose={handleClose}
				>
					<HelpForm
						onFormSubmit={onSubmit}
						{...content?.properties}
						categories={content?.properties.categories.map(
							(category: string, index: number) =>
								({
									children: category,
									categoryNumber: index,
									id: '',
								} as Models.CategoryFilterItem),
						)}
						{...settings?.properties}
					/>
				</Modal>
			)}
		</>
	);
};

import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { useContext } from 'react';
import { ArticleHeader } from 'ui/components/2-molecules/Article';
import { Container } from 'ui/components/4-habitats/Container';

export const ArticleHeaderFeature: React.FC<Umbraco.ArticleHeader> = ({
	...props
}) => {
	const localeContext = useContext(LocaleContext);
	return (
		<Container width="Slim">
			<ArticleHeader
				{...props.content.properties}
				updateDate={localeContext.formatStringDate(
					props.content.properties.updateDate,
				)}
			/>
		</Container>
	);
};

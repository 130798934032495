const ECOMMERCE_API_URL = process.env.NEXT_PUBLIC_ECOMMERCE_API_URL
  ? process.env.NEXT_PUBLIC_ECOMMERCE_API_URL?.endsWith('/')
    ? process.env.NEXT_PUBLIC_ECOMMERCE_API_URL
    : `${process.env.NEXT_PUBLIC_ECOMMERCE_API_URL}/`
  : undefined;

export const getEcommerceUrl = (): string => {
  const ecommerceUrl = process.env.NEXT_PUBLIC_ECOMMERCE_API_URL;
  return ecommerceUrl;
};

const DOMAIN_PROTOCOL = process.env.DOMAIN_PROTOCOL ?? 'https';
const DOMAIN_PORT = process.env.DOMAIN_PORT;

export { ECOMMERCE_API_URL, DOMAIN_PROTOCOL, DOMAIN_PORT };

import { ContentSection } from 'ui/components/4-habitats/ContentSection';
import { ModuleMapFeature } from 'features/Mappers/Module';
import { GridCell } from 'ui/components/4-habitats/Grid';

export const ContentSectionFeature: React.FC<Umbraco.ContentSection> = ({ content, settings }) => {
	const { content: contentModules, title } = content?.properties ?? {};
	const { centered, width } = settings?.properties ?? {};

	return (
		<ContentSection centered={centered} width={width} title={title}>
			{contentModules.map((contentModule, index) => {
				if (
					contentModule?.settings &&
					'desktopWidth' in contentModule?.settings?.properties &&
					'mobileWidth' in contentModule?.settings?.properties
				) {
					const { desktopWidth, mobileWidth } = contentModule?.settings?.properties ?? {};

					return (
						<GridCell key={index} desktopWidth={desktopWidth} mobileWidth={mobileWidth}>
							<ModuleMapFeature contentModule={contentModule} />
						</GridCell>
					);
				}

				return <ModuleMapFeature contentModule={contentModule} key={index} />;
			})}
		</ContentSection>
	);
};

import { useContext } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';

export const CheckoutDictionary = () => {
	const dictionary = useContext(DictionaryContext);

	const data = {
		shoppingCartProductsInCart: dictionary.getValue(
			'ShoppingCart.ProductsInCart',
			null,
			'Products in Cart',
		),
		shoppingCartProductId: dictionary.getValue(
			'ShoppingCart.ProductID',
			null,
			'Product ID',
		),
		shoppingCartProduct: dictionary.getValue(
			'ShoppingCart.Product',
			null,
			'Product',
		),
		shoppingCartShipDate: dictionary.getValue(
			'ShoppingCart.ShipDate',
			null,
			'Expected shipping date',
		),
		shoppingCartQuantity: dictionary.getValue(
			'ShoppingCart.Quantity',
			null,
			'Quantity',
		),
		orderStatus: dictionary.getValue(
			'ShoppingCart.OrderStatus',
			null,
			'Order Status',
		),
		shoppingCartPrice: dictionary.getValue(
			'ShoppingCart.Price',
			null,
			'Price',
		),
		shoppingCartTotal: dictionary.getValue(
			'ShoppingCart.Total',
			null,
			'Total',
		),
		shoppingCartTransportHazard: dictionary.getValue(
			'ShoppingCart.TransportHazard',
			null,
			'Transport hazardous',
		),
		shoppingCartTransportHazardInfo: dictionary.getValue(
			'ShoppingCart.TransportHazardInfo',
			null,
			'Transport hazardous info',
		),
		shoppingCartEarliestPossible: dictionary.getValue(
			'ShoppingCart.EarliestPossible',
			null,
			'Earliest possible',
		),
		shoppingCartShipAtLatestDate: dictionary.getValue(
			'ShoppingCart.ShipAtLatestDate',
			null,
			'Ship at latest date',
		),
		shoppingCartShipment: dictionary.getValue(
			'ShoppingCart.Shipment',
			null,
			'Shipment',
		),
		shoppingCartShippingDate: dictionary.getValue(
			'ShoppingCart.ShippingDate',
			null,
			'Shipping date',
		),
		shippingDateHelpText: dictionary.getValue(
			'ShoppingCart.ShippingDateHelpText',
			null,
			'ShippingDate HelpText todo',
		),
		costDetailsDiscount: dictionary.getValue(
			'CostDetails.Discount',
			null,
			'Discount',
		),
		costDetailsHeading: dictionary.getValue(
			'CostDetails.Heading',
			null,
			'Cost Details',
		),
		costDetailsHeadingNextStep: dictionary.getValue(
			'CostDetails.HeadingNextStep',
			null,
			'Cost details will be calculated in the next step',
		),
		costDetailsSubtotal: dictionary.getValue(
			'CostDetails.Subtotal',
			null,
			'Sub total',
		),
		costDetailsFees: dictionary.getValue('CostDetails.Fees', null, 'Fees'),
		costDetailsHandlingFee: dictionary.getValue(
			'CostDetails.HandlingFee',
			null,
			'Handling fee',
		),
		costDetailsHazardousFee: dictionary.getValue(
			'CostDetails.HazardousFee',
			null,
			'Hazardous fee',
		),
		costDetailsFreight: dictionary.getValue(
			'CostDetails.Freight',
			null,
			'Freight',
		),
		costDetailsSalesTax: dictionary.getValue(
			'CostDetails.SalesTax',
			null,
			'Sales tax',
		),
		costDetailsVat: dictionary.getValue('CostDetails.Vat', null, 'Vat'),
		costDetailsGrandTotal: dictionary.getValue(
			'CostDetails.GrandTotal',
			null,
			'Grand total',
		),
		productsInCartGrandTotal: dictionary.getValue(
			'ProductsInCart.GrandTotal',
			null,
			'Grand total',
		),
		costDetailsProceed: dictionary.getValue(
			'StepNavigation.Proceed',
			null,
			'Proceed',
		),

		basketBrowseProducts: dictionary.getValue(
			'Basket.BrowseProducts',
			null,
			'Browse Products',
		),
		basketEmailQuote: dictionary.getValue(
			'Basket.EmailQuote',
			null,
			'Email Quote',
		),

		quickOrderTypeNumberHere: dictionary.getValue(
			'QuickOrder.TypeNumberHere',
			null,
			'Type number here',
		),
		quickOrderItemNumber: dictionary.getValue(
			'QuickOrder.ItemNumber',
			null,
			'Item number',
		),
		formErrorMessage: dictionary.getValue(
			'Form.ErrorMessage',
			null,
			'This field is required',
		),
		quickOrderQuantity: dictionary.getValue(
			'QuickOrder.Quantity',
			null,
			'Qty.',
		),
		shoppingCartAddToCart: dictionary.getValue(
			'ShoppingCart.AddToCart',
			null,
			'Add to cart',
		),
		shoppingCartEmptyTheCart: dictionary.getValue(
			'ShoppingCart.EmptyTheCart',
			null,
			'Empty the cart',
		),

		ShoppingCartProductNotFound: dictionary.getValue(
			'ShoppingCart.ProductNotFound',
			null,
			'This item does not exist. Please try another item number',
		),
		errorMessageBasketFollowingErrorsOccured: dictionary.getValue(
			'ErrorMessage.FollowingErrorsOccured',
			null,
			'FollowingErrorsOccured',
		),
		errorMessageBasketItemInvalidInLN: dictionary.getValue(
			'ErrorMessage.BasketItemInvalidInLN',
			null,
			'errorMessageBasketItemInvalidInLN',
		),
		errorMessageLNUnavailable: dictionary.getValue(
			'ErrorMessage.LNUnavailable',
			null,
			'errorMessageLNUnavailable',
		),
		errorMessageServerError: dictionary.getValue(
			'ErrorMessage.ServerError',
			null,
			'errorMessageServerError',
		),
		errorMessageAreaExternalError: dictionary.getValue(
			'ErrorMessage.Area.ExternalError',
			null,
			'errorMessageAreaExternalError',
		),
		errorMessageAreaBasket: dictionary.getValue(
			'ErrorMessage.Area.Basket',
			null,
			'errorMessageAreaBasket',
		),
		errorMessageProductMissingFromBasket: dictionary.getValue(
			'ErrorMessage.ProductMissingFromBasket',
			null,
			'errorMessageProductMissingFromBasket',
		),
		errorMessageAreaUnidentified: dictionary.getValue(
			'ErrorMessage.Area.Unidentified',
			null,
			'errorMessageAreaUnidentified',
		),
		errorMessageHazardousFeeCalculationError1: dictionary.getValue(
			'ErrorMessage.HazardousFeeCalculationError1',
			null,
			'errorMessageHazardousFeeCalculationError1',
		),
		errorMessageHazardousFeeCalculationError2: dictionary.getValue(
			'ErrorMessage.HazardousFeeCalculationError2',
			null,
			'errorMessageHazardousFeeCalculationError2',
		),
		errorMessageAreaPrices: dictionary.getValue(
			'ErrorMessage.Area.Prices',
			null,
			'errorMessageAreaPrices',
		),
		errorMessageHandlingFeeCalculationError1: dictionary.getValue(
			'ErrorMessage.HandlingFeeCalculationError1',
			null,
			'errorMessageHandlingFeeCalculationError1',
		),
		errorMessageHandlingFeeCalculationError2: dictionary.getValue(
			'ErrorMessage.HandlingFeeCalculationError2',
			null,
			'errorMessageHandlingFeeCalculationError2',
		),
		errorMessageAreaUnexpected: dictionary.getValue(
			'ErrorMessage.Area.Unexpected',
			null,
			'errorMessageUnexpected',
		),
		errorMessageUnexpectedError: dictionary.getValue(
			'ErrorMessage.UnexpectedError',
			null,
			'ErrorMessage.UnexpectedError',
		),
		shippingMethodShipCollect: dictionary.getValue(
			'InformationForm.ShipCollect',
			null,
			'Ship Collect',
		),
		shippingMethodFedExAir: dictionary.getValue(
			'InformationForm.FedExAir',
			null,
			'FedEx Air Prepaid and Add',
		),
		shippingMethodFedExGround: dictionary.getValue(
			'InformationForm.FedExGround',
			null,
			'FedEx Ground Prepaid and Add',
		),
		errorMessageAreaUpstreamServiceUnavailable: dictionary.getValue(
			'ErrorMessage.Area.UpstreamServiceUnavailable',
			null,
			'UpstreamServiceUnavailable',
		),
		errorMessageProductNotFound: dictionary.getValue(
			'ErrorMessage.ProductNotFound',
			null,
			'errorMessageProductNotFound',
		),
		errorMessageProductIsDiscontinued: dictionary.getValue(
			'ErrorMessage.ProductIsDiscontinued',
			null,
			'errorMessageProductIsDiscontinued',
		),
		errorMessageProductNotAvailableForBuyer: dictionary.getValue(
			'ErrorMessage.ProductNotAvailableForBuyer',
			null,
			'errorMessageProductNotAvailableForBuyer',
		),
		errorMessageProductNotForSaleInLN: dictionary.getValue(
			'ErrorMessage.ProductNotForSaleInLN',
			null,
			'errorMessageProductNotForSaleInLN',
		),
		errorMessageAreaProducts: dictionary.getValue(
			'ErrorMessage.Area.Products',
			null,
			'errorMessageAreaProducts',
		),
		errorMessageProductNotFoundInLn: dictionary.getValue(
			'ErrorMessage.ProductNotFoundInLn',
			null,
			'errorMessageProductNotFoundInLn',
		),
	};

	return data;
};

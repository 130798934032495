import Script from 'next/script';
export interface CookieConsentProps {
	culture: string;
}

export const CookieConsent: React.FC<CookieConsentProps> = (props) => {
	return (
		<Script
			id="Cookiebot"
			src="https://consent.cookiebot.com/uc.js"
			data-cbid="d81e3384-92fe-4575-91db-3deb479430bb"
			data-culture={props.culture}
			data-blockingmode="auto"
			strategy="beforeInteractive"
		/>
	);
};

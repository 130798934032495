import { ProductsInCart } from 'ui/components/3-organisms/ProductsInCart';
import {
	selectCartProducts,
	currentCartId,
	setCart,
	costDetails,
	information,
	deliveryInformation,
	currency,
	total,
	setBasketInformation,
	resetCart,
	earliestShipping,
	hasCardPayment,
} from 'application/adapters/store/slices/cartSlice';
import {
	updateInfo,
	validate,
	getShippingMethods,
	addOrUpdateBasketOrderFees,
	callInvoicePaymentCheckout,
	addOrUpdateBasketOrderTax,
} from 'application/repositories/ecommerceRepository';
import { useSelector, useDispatch } from 'react-redux';
import { StepNavigation } from 'ui/components/2-molecules/Navigation/StepNavigation';
import { useEffect, useContext, useRef, useState, useCallback } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { Container, InformationForm } from 'ui/components';
import { useRouter } from 'next/router';
import { checkoutNavigationSteps } from 'application/repositories/checkoutRepository';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import { CheckoutDictionary as checkoutDictionaryFunc } from '../_checkoutDictionary';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { EcommerceApiError } from 'helpers/error';
import formatCartPrices from 'helpers/formatCartPrices';
import formatProductPrices from 'helpers/formatProductPrices';

export const InformationPageFeature: React.FC<Umbraco.InformationPage> = ({
	content,
	accessToken,
}) => {
	const router = useRouter();
	const proceedLink = content.properties.proceedLink.url;
	const receiptPageLink = content.properties.receiptPageLink.url;
	const previousLink = content.properties.previousLink.url;
	const requiredToggle = content.properties.requiredToggle;
	const selectedCartProducts = useSelector(selectCartProducts);
	const basketInformation = useSelector(information);
	const dispatch = useDispatch();
	const basketId = useSelector(currentCartId);
	const cDetails = useSelector(costDetails);
	const cTotal = useSelector(total);
	const delivery = useSelector(deliveryInformation);
	const currencyCode = useSelector(currency);
	const culture = useSelector(currentCulture);
	const dictionary = useContext(DictionaryContext);
	const checkoutDictionary = checkoutDictionaryFunc();
	const parentRef = useRef<{ getValues; validate; errors }>();
	const steps: Models.NavigationStep[] = checkoutNavigationSteps(dictionary);
	const localeContext = useContext(LocaleContext);
	const informationFormDictionary = {
		shipCollect: dictionary.getValue(
			'InformationForm.ShipCollect',
			null,
			'Ship Collect',
		),
		fedExGround: dictionary.getValue(
			'InformationForm.FedExGround',
			null,
			'FedEx Ground',
		),
		fedExAir: dictionary.getValue(
			'InformationForm.FedExAir',
			null,
			'FedEx Air',
		),
		purchaseNumberLabel: dictionary.getValue(
			'InformationForm.PurchaseNumberLabel',
			null,
			'Purchase Number or Other Ref:',
		),
		purchaseNumberPlaceholder: dictionary.getValue(
			'InformationForm.PurchaseNumberPlaceholder',
			null,
			'Test DK',
		),
		sDSReceiptEmailLabel: dictionary.getValue(
			'InformationForm.SDSReceiptEmailLabel',
			null,
			'SDS Receipt e-mail address:',
		),
		sDSReceiptEmailPlaceholder: dictionary.getValue(
			'InformationForm.SDSReceiptEmailPlaceholder',
			null,
			'administration@struers.dk',
		),
		additionalReferenceLabel: dictionary.getValue(
			'InformationForm.AdditionalReferenceLabel',
			null,
			'Additional Reference:',
		),
		additionalReferencePlaceholder: dictionary.getValue(
			'InformationForm.AdditionalReferencePlaceholder',
			null,
			'None',
		),
		additionalDeliveryNotesLabel: dictionary.getValue(
			'InformationForm.AdditionalDeliveryNotesLabel',
			null,
			'Additional delivery notes (room numbers, attention etc)',
		),
		additionalDeliveryNotesPlaceholder: dictionary.getValue(
			'InformationForm.AdditionalDeliveryNotesPlaceholder',
			null,
			'None',
		),
		paymentMethodLabel: dictionary.getValue(
			'InformationForm.PaymentMethodLabel',
			null,
			'Choose method payment',
		),
		paymentMethodPlaceholder: dictionary.getValue(
			'InformationForm.PaymentMethodPlaceholder',
			null,
			'Purchase Number or Other Ref:"',
		),
		paymentMethod2Label: dictionary.getValue(
			'InformationForm.PaymentMethod2Label',
			null,
			'Purchase Number or Other Ref:"',
		),
		paymentMethod2Placeholder: dictionary.getValue(
			'InformationForm.PaymentMethod2Placeholder',
			null,
			'Purchase Number or Other Ref:"',
		),
		uSDeliveryLabel: dictionary.getValue(
			'InformationForm.USDeliveryLabel',
			null,
			'Choose delivery method',
		),
		termsConditionsLabel: dictionary.getValue(
			'InformationForm.TermsConditionsLabel',
			null,
			'Terms and conditions',
		),
		termsConditionsText: dictionary.getValue(
			'InformationForm.TermsConditionsText',
			null,
			'I accept the terms and conditions',
		),
		invoice: dictionary.getValue(
			'InformationForm.Invoicelabel',
			null,
			'Invoice',
		),
		creditcard: dictionary.getValue(
			'InformationForm.Creditcardlabel',
			null,
			'Credit Card',
		),
	};
	const [shippingOptions, setShippingOptions] = useState([]);
	const businessPartner = useSelector(currentCustomer);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { user } = useAuthenticationContext();
	const earliest = useSelector(earliestShipping);
	const cardPayment = useSelector(hasCardPayment);
	const [errors, setErrors] = useState<EcommerceApiError[]>([]);

	const getShippingAndTaxes = useCallback(async () => {
		setIsLoading(true);
		const shipping = await getShippingMethods(
			accessToken,
			businessPartner?.id,
			culture,
		);

		shipping.map((shippingMethod) => {
			if (shippingMethod.carrierDisplayName == 'Ship Collect')
				return (
					checkoutDictionary?.shippingMethodShipCollect ??
					shippingMethod.carrierDisplayName
				);
			if (shippingMethod.carrierDisplayName == 'FedEx Ground')
				return (
					checkoutDictionary?.shippingMethodFedExAir ??
					shippingMethod.carrierDisplayName
				);
			if (shippingMethod.carrierDisplayName == 'FedEx Air')
				return (
					checkoutDictionary?.shippingMethodFedExGround ??
					shippingMethod.carrierDisplayName
				);
			return shippingMethod.carrierDisplayName;
		});

		const transformedShipping = shipping.map((shippingMethod) => {
			if (shippingMethod.carrierDisplayName === 'FedEx Air') {
				shippingMethod.carrierDisplayName =
					checkoutDictionary.shippingMethodFedExAir;
			}

			if (shippingMethod.carrierDisplayName === 'FedEx Ground') {
				shippingMethod.carrierDisplayName =
					checkoutDictionary.shippingMethodFedExGround;
			}

			if (shippingMethod.carrierDisplayName === 'Ship Collect') {
				shippingMethod.carrierDisplayName =
					checkoutDictionary.shippingMethodShipCollect;
			}

			return shippingMethod;
		});
		setShippingOptions(transformedShipping);

		let res: any = await addOrUpdateBasketOrderTax(
			accessToken,
			basketId,
			businessPartner?.id,
			culture,
		);

		if (!shippingOptions?.length) {
			res = await onShippingChange(null);
		}
		dispatch(setCart(formatCartPrices(res, localeContext, currencyCode)));
		setIsLoading(false);
	}, []);

	useEffect(() => {
		getShippingAndTaxes();
	}, []);

	const handleProceed = async () => {
		const values = parentRef.current.getValues();
		await parentRef.current.validate();

		const errors = parentRef.current.errors;

		if (errors && Object.keys(errors).length === 0) {
			setIsLoading(true);
			values.basketId = basketId;

			if (values.paymentMethod === 'Invoice') {
				dispatch(setBasketInformation(values));
				await updateInfo(accessToken, {
					...values,
					bpId: businessPartner?.id,
				});
				const res = await callInvoicePaymentCheckout(
					accessToken,
					basketId,
				);
				if (res) {
					if (receiptPageLink) {
						await router.push(`${receiptPageLink}?orderId=${res}`);
						dispatch(resetCart());
					}
				}
			} else {
				await updateInfo(accessToken, {
					...values,
					bpId: businessPartner?.id,
				});
				dispatch(setBasketInformation(values));
				if (proceedLink) {
					router.push(`${proceedLink}`);
				}
			}
			setIsLoading(false);
		} else {
			alert(
				dictionary.getValue(
					'Checkout.FormErrorMessage',
					null,
					'Some fields were not filled in correctly. Please fix them to proceed.',
				),
			);
		}
	};

	const handlePrevious = () => {
		setIsLoading(true);
		const values = parentRef.current.getValues();
		values.basketId = basketId;
		if (values.paymentMethod === null) {
			values.paymentMethod = '';
		}
		updateInfo(accessToken, {
			...values,
			bpId: businessPartner?.id,
		}).then((response) => {
			dispatch(
				setCart(
					formatCartPrices(response, localeContext, currencyCode),
				),
			);
			if (previousLink) {
				router.push(previousLink);
			}
			setIsLoading(false);
		});
	};

	const onShippingChange = async (option) => {
		setIsLoading(true);

		const res = await addOrUpdateBasketOrderFees(
			accessToken,
			basketId,
			businessPartner?.id,
			option,
			culture,
		);
		dispatch(setCart(formatCartPrices(res, localeContext, currencyCode)));
		setIsLoading(false);
	};

	useEffect(() => {
		if (basketId) {
			setIsLoading(true);
			validate(accessToken, basketId, businessPartner?.id, currentCulture)
				.then((cart) => {
					dispatch(
						setCart(
							formatCartPrices(cart, localeContext, currencyCode),
						),
					);
					setIsLoading(false);
				})
				.catch((errors) => {
					const errorsArray = [];

					const currentBasket = {
						culture,
						basketId: basketId,
						bpId: businessPartner?.id,
						items: selectedCartProducts,
					};

					errors.map((error) => {
						// ProductNotFound (600) - Http: 404 (Not Found)
						if (error.code === 600) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaProducts;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotFound;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						if (error.code === 403) {
							// ProductNotFoundInLN (403) - Http: 404 (Not Found)
							if (error.name === 'ProductNotFoundInLN') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageProductNotFoundInLn;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in top of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							// ProductDiscontinuedInLN (403) - Http: 404 (Not Found)
							if (error.name === 'ProductDiscontinuedInLN') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageProductIsDiscontinued;
								errorsArray.push({
									area: dictionaryArea,
									message: dictionaryMessage,
								});
							}
						}

						// ProductNotAvailableForBuyer (601) - Http: 400 (Bad Request)
						if (error.code === 601) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaProducts;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotAvailableForBuyer;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// ProductNotForSaleInLN (405) - Http: 404 (Not Found)
						if (error.code === 405) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotForSaleInLN;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// UpstreamServiceUnavailable (409) - Http: 503 (Service Unavailable)
						if (error.code === 409) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaUpstreamServiceUnavailable;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotFound;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// LNUnavailable (400) - Http: 503 (ServiceUnavailable)
						if (error.code === 400) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageLNUnavailable;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// HandlingFeeCalculationError (202) - Http: 404 (Not found)
						if (error.code === 202) {
							if (error.area === 'External System') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError1;
								const sku = error.subjectId; // Do not delete, is actually used
								const bpId = currentBasket.bpId; // Do not delete, is actually used
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							if (error.area === 'Prices') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaPrices;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError2;
								// basketId is already defined in start of compontent
								const bpId = currentBasket.bpId; // Do not delete, is actually used
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}
						}
					});

					if (errorsArray.length !== 0) {
						setErrors(errorsArray);
					} else {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaExternalError;
						const dictionaryMessage =
							checkoutDictionary.errorMessageUnexpectedError;
						errorsArray.push({
							area: dictionaryArea,
							message: dictionaryMessage,
						});
						setErrors(errorsArray);
					}

					setIsLoading(false);
					return Promise.resolve(errors.response);

					// throw new Error('Basket did not update', e);
				});
		}
	}, [basketId, dispatch]);

	//todo could this be refactored into some middleware?
	if (user?.role === 'Requester' || user?.role === 'Dealer Requester') {
		return (
			<Container width="Standard">
				<h1>
					{dictionary.getValue(
						'Authentication.NotAuthorized',
						null,
						'Authentication.NotAuthorized',
					)}
				</h1>
			</Container>
		);
	}
	if (!selectedCartProducts?.length) {
		return (
			<Container
				width="Standard"
				className="u-bottom-padding--xxl u-top-padding--xxl"
			>
				<h1>
					{dictionary.getValue(
						'Basket.NoProductsInBasket',
						null,
						'You have no products in basket',
					)}
				</h1>
			</Container>
		);
	}

	return (
		<>
			<StepNavigation
				steps={steps}
				handlePrevious={handlePrevious}
				handleProceed={handleProceed}
				currentStep={1}
				heading={dictionary.getValue(
					'Checkout.Heading',
					null,
					'Checkout',
				)}
				isProceedDisabled={errors.length > 0}
			/>
			<InformationForm
				ref={parentRef}
				basketInformation={basketInformation}
				dictionary={informationFormDictionary}
				shippingOptions={shippingOptions}
				onShippingChange={onShippingChange}
				hasCardPayment={cardPayment}
				requiredToggle={requiredToggle}
			/>
			<ProductsInCart
				dictionary={checkoutDictionary}
				products={selectedCartProducts.map((p) =>
					formatProductPrices(p, localeContext, currencyCode),
				)}
				costDetails={{
					costs: cDetails,
					total: {
						...cTotal,
						formattedTotal: localeContext.formatPrice(
							cTotal.totalPrice,
							currencyCode,
						),
					},
				}}
				isBasketView={false}
				handleProceed={handleProceed}
				deliveryInformation={delivery}
				currency={currencyCode}
				earliestShipping={earliest}
				errors={errors}
				shippingAddress={
					businessPartner.addresses.find(
						(address) => address.key === 'shipTo',
					).value
				}
			/>
			{isLoading && (
				<PageSpinner
					size="large"
					text={dictionary.getValue(
						'Checkout.WaitingMessageInformationPage',
						null,
						'Please wait a moment, we are processing your information',
					)}
					/>
			)}
		</>
	);
};

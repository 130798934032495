import Head from 'next/head';

export interface GoogleTagManagerProps {
	containerId?: string;
}

export const GoogleTagManager: React.FC<GoogleTagManagerProps> = (props) => {
	if (!props.containerId) return;

	const gtmHead = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
	new Date().getTime(), event: 'gtm.js'
}); var f = d.getElementsByTagName(s)[0],
	j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
	'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
}) (window, document, 'script', 'dataLayer', '${props.containerId}');`;

	return (
		<>
			<Head>
				<script>window.dataLayer = window.dataLayer || [];</script>
				{/*<!-- Google Tag Manager --> */}
				<script dangerouslySetInnerHTML={{ __html: gtmHead }} />
				{/*<!-- End Google Tag Manager --> */}
			</Head>

			{/* <!-- Google Tag Manager (noscript) --> */}
			<noscript>
				<iframe
					src={'https://www.googletagmanager.com/ns.html?id=' + props.containerId}
					height="0"
					width="0"
					style={{ display: 'none', visibility: 'hidden' }}
				></iframe>
			</noscript>
			{/* <!-- End Google Tag Manager (noscript) --> */}
		</>
	);
};

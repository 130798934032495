import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { Container, Heading } from 'ui/components';

export const CookieInfoFeature: React.FC<Umbraco.CookieInfo> = ({
	content,
}) => {
	const declarationRef = useRef<HTMLDivElement | null>(null);
	const declarationScript = `
    <script
      id="CookieDeclaration"
      src="https://consent.cookiebot.com/d81e3384-92fe-4575-91db-3deb479430bb/cd.js"
      async
    >
    </script>
  `;

	useEffect(() => {
		const fragment = document
			.createRange()
			.createContextualFragment(declarationScript);
		declarationRef?.current?.append(fragment);
	}, [declarationScript, declarationRef]);
	return (
		<Container width="Slim">
			<article>
				<Heading headingLevel="h2">
					{content?.properties?.heading}
				</Heading>
			</article>
			<div
				className={classNames(
					'flex flex-col lg:flex-row justify-center mb-20 text-[1.6rem] max-w-4xl lg:max-w-[780px] [word-break:break-word] [hyphens:auto]',
				)}
			>
				<div ref={declarationRef}></div>
			</div>
		</Container>
	);
};

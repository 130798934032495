import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { API_URL } from '../cms/constants';

export const getOrderFromAdapter: (
	accessToken: any,
	orderNumber: number | string | string[],
	culture: string,
	addAnalyticsData?: boolean,
) => Promise<any> = async (
	accessToken,
	orderNumber,
	culture,
	addAnalyticsData = false,
) => {
		const method = 'GET';
		const headers = {
			Accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		};
		headers['Authorization'] = `Bearer ${accessToken}`;

		const endpointUrl = `${API_URL}api/v1/Order/Order?orderNumber=${orderNumber}&locale=${culture}&AddAnalyticsData=${addAnalyticsData}`;

		return axios({
			url: endpointUrl,
			method: method,
			maxRedirects: 0,
			headers,
		})
			.then((res) => {
				const { data } = res;
				return data;
			})
			.catch((error) => {
				const message = error?.response?.data?.message ?? error;
				const statusCode = error?.response?.status || 500;

				if (statusCode === 404) {
					throw new ApiError('PageNotFound', 404, 'Page not found');
				}

				if (statusCode === 401 || statusCode === 403) {
					// "401 Unauthorized" really means "unauthenticated"
					// "403 Forbidden" really means "unauthorized"
					return error?.response?.data?.data;
				}

				if (statusCode === 301 || statusCode === 302) {
					throw new ApiRedirect(statusCode, error.response.data.url);
				}

				if (statusCode === 500) {
					throw new ApiError('InternalServerError', 500, message);
				}

				throw new ApiError('Unknown error', statusCode, message);
			});
	};

export const getOrderHistoryFromAdapter: (
	accessToken: any,
	bpId: number | string,
	monthsBack: number | string,
) => Promise<any> = async (accessToken, bpId, monthsBack = 6) => {
	const method = 'GET';
	const headers = {
		Accept: 'application/json, text/plain, */*',
		'Content-Type': 'application/json',
	};
	headers['Authorization'] = `Bearer ${accessToken}`;

	const endpointUrl = `${API_URL}api/v1/Order/OrderHistory?bpId=${bpId}&monthsBack=${monthsBack}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;
			return data;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 404) {
				throw new ApiError('PageNotFound', 404, 'Page not found');
			}

			if (statusCode === 401 || statusCode === 403) {
				// "401 Unauthorized" really means "unauthenticated"
				// "403 Forbidden" really means "unauthorized"
				return error?.response?.data?.data;
			}

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			if (statusCode === 500) {
				throw new ApiError('InternalServerError', 500, message);
			}

			throw new ApiError('Unknown error', statusCode, message);
		});
};

import { FeaturedProducts } from 'ui/components/3-organisms/FeaturedProducts';
import {
	getProductById,
	updateBasket,
} from 'application/repositories/ecommerceRepository';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useDispatch, useSelector } from 'react-redux';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import {
	currency,
	currentCartId,
	setCart,
} from 'application/adapters/store/slices/cartSlice';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';

export const FeaturedProductsFeature = ({ content, accessToken, culture }) => {
	const { title, productsPicker } = content?.properties ?? {};
	const productIds = productsPicker?.split(',');
	const [mappedProducts, setMappedProducts] = useState([]);
	const { status } = useSession();
	const dictionary = useContext(DictionaryContext);
	const businessPartner = useSelector(currentCustomer);
	const basketId = useSelector(currentCartId);
	const dispatch = useDispatch();
	const localeContext = useContext(LocaleContext);
	const currencyCode = useSelector(currency);
	const mapProducts = useCallback(async () => {
		if (productIds?.length > 0) {
			const products = await Promise.all(
				productIds.map(async (id: string) => {
					const product = await getProductById(
						id,
						accessToken,
						culture,
						businessPartner?.id,
					);
					return {
						...product,
						price: {
							...product?.price,
							formattedPrice: localeContext.formatPrice(
								product?.price?.price,
								product?.price?.currencyCode,
							),
						},
					};
				}),
			);
			setMappedProducts(products.filter((p) => p != null));
		}
	}, [productIds, accessToken, culture]);

	useEffect(() => {
		mapProducts();
	}, [businessPartner?.id]);

	const handleAddProductToCart = (cartProduct) => {
		updateBasket(
			accessToken,
			{
				basketId,
				cultureCode: culture,
				bpId: businessPartner?.id,
				...cartProduct,
			},
			false,
			true,
		).then((res) => {
			dispatch(
				setCart(formatCartPrices(res, localeContext, currencyCode)),
			);
		});
	};

	return (
		<FeaturedProducts
			numberOfProducts={mappedProducts.length}
			products={mappedProducts}
			isLoggedIn={status === 'authenticated'}
			heading={title}
			columns={4}
			loadMoreText={dictionary.getValue(
				'FeaturedProducts.LoadMore',
				null,
				'Load more products',
			)}
			handleAddProductToCart={handleAddProductToCart}
			hasBp={businessPartner?.id ? true : false}
		/>
	);
};

import styles from './ProductSearchList.module.scss';
import classNames from 'classnames';
import { ProductCard, Icon } from 'ui/components';
import {
	Pagination,
	PaginationProps,
} from 'ui/components/2-molecules/Navigation/Pagination';
import { Heading } from 'ui/components/1-atoms';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useContext, useEffect, useState } from 'react';
import { Container } from 'ui/components/4-habitats/Container';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';

export interface ProductSearchListProps {
	className?: string;
	heading: string;
	columns: number;
	numberOfProducts: number;
	products: Models.Product[];
	paginationData?: PaginationProps;
	isLoggedIn: boolean;
	isLoading?: boolean;
	searchWord: string;
	sortOptions: string[];
	handleAddProductToCart: (product: Models.UpdateBasketProduct) => void;
	hasBp: boolean;
	sortingDefaultValue?: string;
	setSortingDefaultValue?: React.Dispatch<React.SetStateAction<string>>;
}

export const ProductSearchList: React.FC<ProductSearchListProps> = ({
	className,
	columns,
	numberOfProducts,
	products,
	paginationData,
	isLoggedIn,
	isLoading,
	searchWord = 'Polishing',
	sortOptions,
	handleAddProductToCart,
	hasBp,
	sortingDefaultValue,
	setSortingDefaultValue,
}) => {
	// Gets column width for Product Card
	const getColumnWidth = (columns: number) => {
		if (columns > 4) columns = 4;
		if (!columns || columns === 1) columns = 2;
		const columnsWidth = Math.round(100 / columns);
		return JSON.stringify(columnsWidth) as Models.GridCellWidth;
	};
	const columnWidth = getColumnWidth(columns);
	const dictionary = useContext(DictionaryContext);
	// Defines image sizes for Product Card
	const imageSizes = '(max-width: 768px) 50vw, ' + columnWidth + 'vw';
	const [isOpen, setIsOpen] = useState(false);

	function handleChange(event) {
		const params = new URL(document.location as any).searchParams;
		const query = params.get('query');
		const page = params.get('page');

		history.pushState(
			{},
			'',
			`/search?query=${query}&page=${page}&sorting=${event.target.value}`,
		);
	}

	const toggleList = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		const params = new URL(document.location as any).searchParams;
		const sorting = params.get('sorting');
		if (sorting && sortOptions) {
			for (let i = 0; i < sortOptions.length; i++) {
				if (sorting === sortOptions[i]) {
					setSortingDefaultValue(sortOptions[i]);
					break;
				}
			}
		}
	});

	return (
		<Container width="Standard">
			<div className={classNames(styles.ProductSearchList)}>
				{isLoading && <PageSpinner size="large" />}
				<Heading
					headingLevel="h2"
					style="xl"
					className={classNames(styles.ProductSearchList_heading)}
				>
					{dictionary.getValue('Search.Heading', null, 'Results for')}{' '}
					{'"'}
					{searchWord}
					{'"'}
				</Heading>
				<Heading
					headingLevel="h5"
					style="xs"
					className={classNames(styles.ProductSearchList_amount)}
				>
					{numberOfProducts != null
						? `${numberOfProducts} ${dictionary.getValue(
								'Search.Products',
								null,
								'products',
						  )}`
						: ' '}
					<div
						className={classNames(styles.ProductSearchListSorting)}
					>
						<span
							className={classNames(
								styles.ProductSearchListSortingTitle,
							)}
						>
							{dictionary.getValue(
								'Search.SortBy',
								null,
								'Sort by:',
							)}
						</span>
						<div
							className={styles.ProductSearchListSorting_wrapper}
						>
							<select
								className={classNames(
									styles.ProductSearchListSorting_select,
								)}
								value={sortingDefaultValue}
								onChange={handleChange}
								onClick={toggleList}
								onBlur={toggleList}
							>
								{sortOptions?.map((option, index) => (
									<option
										className={
											styles.ProductSearchListSorting_option
										}
										value={option}
										key={index}
									>
										{dictionary.getValue(
											'Search.' + option.replace(' ', ''),
											null,
											option,
										)}{' '}
									</option>
								))}
							</select>
							<Icon
								size="xl"
								className={classNames(
									styles.ProductSorting_buttonIcon,
									{
										[styles.isOpen]: isOpen,
									},
								)}
							>
								<ChevronIcon />
							</Icon>
						</div>
					</div>
				</Heading>

				<div
					className={classNames(
						styles.ProductSearchListItems,
						className,
					)}
				>
					{products.map((product, index) => {
						if (index < numberOfProducts) {
							return (
								<ProductCard
									product={product as Models.Product}
									columnWidth={columnWidth}
									key={index}
									imageSizes={imageSizes}
									isLoggedIn={isLoggedIn}
									onAddToCart={handleAddProductToCart}
									hasBp={hasBp}
								/>
							);
						}
					})}
				</div>
				<Pagination {...paginationData} />
			</div>
		</Container>
	);
};

import styles from './Dashboard.module.scss';
import { ReactComponent as ChevronIcon } from 'ui/icons/icon-chevron.svg';
import { Button, Heading, Paragraph, Picture, Container, LinkButton } from 'ui/components';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useContext } from 'react';
import { Link } from 'ui/components/1-atoms';

export interface DashboardProps {
	image?: Umbraco.Image;
	name?: string;
	company?: string | React.ReactElement;
	links?: Array<Umbraco.Link>;
	statistics?: { amount: string; text: string }[];
	contacts?: Models.Contact[];
	isLoggedIn?: boolean;
	accountLink?: string;
	onFirstButtonClick?: () => void;
	onSecondButtonClick?: () => void;
}

export const Dashboard: React.FC<DashboardProps> = ({
	image,
	name,
	company,
	links,
	statistics,
	contacts,
	isLoggedIn = true,
	accountLink,
	onFirstButtonClick,
	onSecondButtonClick,
}) => {
	const dictionary = useContext(DictionaryContext);
	if (!isLoggedIn) return null;

	return (
		<Container width="Standard">
			<div className={styles.Dashboard}>
				{image && (
					<div className={styles.Dashboard_image}>
						<Picture
							url={image.url}
							properties={image.properties}
							sizes="100vw"
						/>
					</div>
				)}

				<div className={styles.Dashboard_infoContainer}>
					<div className={styles.Dashboard_info}>
						<div className={styles.Dashboard_presentation}>
							<Heading headingLevel="h3" style="md">
								{dictionary.getValue(
									'Dashboard.Welcome',
									null,
									'Welcome',
								)}
								{', '} {name}
							</Heading>
							<Paragraph>{company}</Paragraph>
						</div>

						<div className={styles.Dashboard_buttonContainer}>
							{onFirstButtonClick && (
								<div className={styles.Dashboard_buttonWrapper}>
									<Button
										onClick={onFirstButtonClick}
										style="secondary"
										className={styles.Dashboard_button}
									>
										{dictionary.getValue(
											'Dashboard.ViewAccount',
											null,
											'View account',
										)}
									</Button>
								</div>
							)}

							{onSecondButtonClick && (
								<div className={styles.Dashboard_buttonWrapper}>
									<Button
										onClick={onSecondButtonClick}
										style="secondary"
										className={styles.Dashboard_button}
									>
										{dictionary.getValue(
											'Action.Logout',
											null,
											'Log out',
										)}
									</Button>
								</div>
							)}
						</div>
					</div>

					<div className={styles.Dashboard_links}>
						{links &&
							links.map((item, index) => (
								<div
									className={styles.Dashboard_linkContainer}
									key={index}
								>
									<LinkButton
										withBackIcon={<ChevronIcon />}
										className={styles.Dashboard_link}
										url={item.url}
									>
										{item.name}
									</LinkButton>
								</div>
							))}
					</div>

					{statistics && (
						<div className={styles.Dashboard_statisticsContainer}>
							{statistics.map((item, idx) => (
								<div
									key={idx}
									className={styles.Dashboard_statistics}
								>
									<Heading
										headingLevel="h3"
										style="sm"
										className={
											styles.Dashboard_statisticsHeader
										}
									>
										{item.amount}
									</Heading>
									<Paragraph>{item.text}</Paragraph>
								</div>
							))}
						</div>
					)}

					{contacts && (
						<>
							<Heading
								headingLevel="h4"
								style="sm"
								className={styles.Dashboard_contactHeader}
							>
								{dictionary.getValue(
									'Dashboard.Contacts',
									null,
									'Your contacts at struers',
								)}
							</Heading>
							<div className={styles.Dashboard_contactContainer}>
								{contacts.map((item, idx) => (
									<div
										key={idx}
										className={styles.Dashboard_contact}
									>
										{item.image && (
											<div
												className={
													styles.Dashboard_contactImageContainer
												}
											>
												<Picture
													url={item.image}
													className={
														styles.Dashboard_contactImage
													}
													isCover={true}
												/>
											</div>
										)}
										<div>
											<div
												className={
													styles.Dashboard_contactDetails
												}
											>
												<Paragraph
													style="md"
													className={
														styles.Dashboard_contactName
													}
												>
													{item.name}
												</Paragraph>
												<Paragraph style="sm">
													{item.title}
												</Paragraph>
											</div>
											<Paragraph style="sm">
												{item.email}
											</Paragraph>
											<Paragraph style="sm">
												{item.phone}
											</Paragraph>
										</div>
									</div>
								))}
							</div>
						</>
					)}
				</div>
			</div>
		</Container>
	);
};

import {
	currentCartId,
	setCart,
	currency,
	costDetails,
	total,
} from 'application/adapters/store/slices/cartSlice';
import { updateBasketMultiple } from 'application/repositories/ecommerceRepository';
import { useSelector, useDispatch } from 'react-redux';
import { OrderDetails, ProductsInCart } from 'ui/components';
import { useContext, useState } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { CheckoutDictionary as checkoutDictionaryFunc } from '../_checkoutDictionary';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';
import formatOrderLinePrices from 'helpers/formatOrderLinePrices';
export interface OrderDetailPageProps {
	accessToken: string;
	order: Models.Order;
}

export const OrderDetailPageFeature: React.FC<OrderDetailPageProps> = ({
	accessToken,
	order,
}) => {
	const dispatch = useDispatch();
	const basketId = useSelector(currentCartId);
	const cDetails = useSelector(costDetails);
	const dictionary = useContext(DictionaryContext);
	const checkoutDictionary = checkoutDictionaryFunc();
	const orderDetailsDictionary = {
		salesOrderNumber: dictionary.getValue(
			'OrderDetails.SalesOrderNumber',
			null,
			'Sales order number:',
		),
		plannedDeliveryDate: dictionary.getValue(
			'OrderDetails.PlannedDeliveryDate',
			null,
			'Est. Delivery date',
		),
		orderStatus: dictionary.getValue(
			'OrderDetails.OrderStatus',
			null,
			'Order status:',
		),
		purchasedOrderNumber: dictionary.getValue(
			'OrderDetails.PurchasedOrderNumber',
			null,
			'Purchased order no.:',
		),
		sdsRecipientEmail: dictionary.getValue(
			'OrderDetails.SdsRecipientEmail',
			null,
			'SDS recipient email address:',
		),
		customerReference: dictionary.getValue(
			'OrderDetails.CustomerReference',
			null,
			'Customer reference:',
		),
		reorderButtonText: dictionary.getValue(
			'OrderDetails.ReorderButtonText',
			null,
			'Re-order',
		),
		reorderErrorMessage: dictionary.getValue(
			'OrderDetails.ReorderErrorMessage',
			null,
			'An error occurred while reordering. The following items could not be added to your basket:',
		),
	};
	const businessPartner = useSelector(currentCustomer);
	const culture = useSelector(currentCulture);
	const [reorderDisabled, setReorderDisabled] = useState(false);
	const localeContext = useContext(LocaleContext);
	const currencyCode = useSelector(currency);
	const handleReorder = () => {
		if (!order?.lines) return;

		setReorderDisabled(true);

		const basketProducts = order?.lines.map((line) => {
			return {
				sku: line.itemNo,
				quantityDelta: line.quantity,
			};
		});

		const newBasketData = {
			culture,
			basketId: basketId,
			bpId: businessPartner?.id,
			items: basketProducts,
		};

		updateBasketMultiple(accessToken, newBasketData)
			.then((res) => {
				if (res) {
					dispatch(
						setCart(
							formatCartPrices(res, localeContext, currencyCode),
						),
					);
				}

				if (res.nonExistingProducts && res.nonExistingProducts.length) {
					const nonExistingProducts = order?.lines
						.filter((line) => {
							return res.nonExistingProducts.includes(
								line.itemNo,
							);
						})
						.map((line) => `${line.productName} (${line.itemNo})`);

					//TODO: Refactor to a modal prompt or inline error message instead of an alert
					alert(
						`${orderDetailsDictionary.reorderErrorMessage}\n\n` +
							nonExistingProducts.join('\n'),
					);
				}

				setReorderDisabled(false);
			})
			.catch((error) => {
				console.error('Error reordering products:', error);
				alert(orderDetailsDictionary.reorderErrorMessage);

				setReorderDisabled(false);
			});
	};

	return (
		<>
			<OrderDetails
				dictionary={orderDetailsDictionary}
				orderNumber={order?.orderNumber}
				orderStatus={order?.orderStatus}
				purchasedOrderNumber={order?.purchaseOrderNumber}
				recipientEmail={order?.recipientEmail}
				customerReference={order?.customerReference}
				plannedDeliveryDate={
					order?.plannedDeliveryDate
						? localeContext.formatStringDate(order?.plannedDeliveryDate)
						: null
				}
				reorderDisabled={reorderDisabled}
				handleReorder={handleReorder}
				reorderButtonText={orderDetailsDictionary.reorderButtonText}
			/>
			<ProductsInCart
				dictionary={checkoutDictionary}
				products={order?.lines.map((p) =>
					formatOrderLinePrices(p, localeContext, currencyCode),
				)}
				costDetails={{
					costs: cDetails,
					total: {
						...order,
						formattedTotal: localeContext.formatPrice(
							order.totalPrice,
							order?.currency,
						),
					},
				}}
				isBasketView={false}
				isOrderDetailsView={true}
				shippingAddress={order?.deliveryAddress}
				invoiceAddress={order?.invoiceAddress}
				currency={order?.currency}
				showHeading={false}
			/>
		</>
	);
};

import { useState } from 'react';
import { ModalHelpFormFeature } from '../ModalHelpForm';

export const ContactFormPickerFeature: React.FC<Umbraco.ContactFormPicker> = ({
	content,
	settings,
}) => {
	return (
		<>
			<ModalHelpFormFeature
				content={content?.properties?.formPicker}
				{...content?.properties?.formPicker}
			></ModalHelpFormFeature>
		</>
	);
};

import axios from 'axios';
import { API_URL } from '../cms/constants';

export const getBusinessPartnersFromAdapter: (
	accessToken: string,
) => Promise<Models.BusinessPartner[]> = async (accessToken) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = 'api/v1/ContactInfo/GetBusinessPartners';
	const endpointUrl = `${API_URL}${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	})
		.then((res) => {
			const { data } = res;

			if (!data) {
				return null;
			}
			return data;
		})
		.catch((error) => {
			console.error(
				`ERROR retrieving business partners: ${error?.response?.status} - ${error?.response?.statusText}`,
				error.config,
			);

			return null;
		});
};

import { ProductsInCart } from 'ui/components/3-organisms/ProductsInCart';
import {
	selectCartProducts,
	currentCartId,
	setCart,
	costDetails,
	earliestShipping,
	deliveryInformation,
	currency,
	total,
} from 'application/adapters/store/slices/cartSlice';
import { updateShipping } from 'application/repositories/ecommerceRepository';
import { useSelector, useDispatch } from 'react-redux';
import { Container, StepNavigation } from 'ui/components';
import { useEffect, useContext, useState } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useRouter } from 'next/router';
import { checkoutNavigationSteps } from 'application/repositories/checkoutRepository';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { PageSpinner } from 'ui/components/1-atoms/Media/PageSpinner';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import { CheckoutDictionary as checkoutDictionaryFunc } from '../_checkoutDictionary';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import { EcommerceApiError } from 'helpers/error';
import formatCartPrices from 'helpers/formatCartPrices';
import formatProductPrices from 'helpers/formatProductPrices';

export const DeliveryPageFeature: React.FC<Umbraco.DeliveryPage> = ({
	content,
	accessToken,
}) => {
	const router = useRouter();
	const proceedLink = content?.properties?.proceedLink?.url;
	const selectedCartProducts = useSelector(selectCartProducts);
	const dispatch = useDispatch();
	const basketId = useSelector(currentCartId);
	const cDetails = useSelector(costDetails);
	const cTotal = useSelector(total);
	const earliest = useSelector(earliestShipping);
	const delivery = useSelector(deliveryInformation);
	const currencyCode = useSelector(currency);
	const dictionary = useContext(DictionaryContext);
	const checkoutDictionary = checkoutDictionaryFunc();
	const steps: Models.NavigationStep[] = checkoutNavigationSteps(dictionary);
	const businessPartner = useSelector(currentCustomer);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [errors, setErrors] = useState<EcommerceApiError[]>([]);
	const { user } = useAuthenticationContext();
	const localeContext = useContext(LocaleContext);
	const handleProceed = () => {
		if (proceedLink) {
			router.push(proceedLink);
		}
	};

	const shippingUpdated = (left: boolean) => {
		const bpId = businessPartner?.id; // Do not delete, is actually used

		if (basketId) {
			setIsLoading(true);
			updateShipping(accessToken, {
				basketId,
				earliest: left,
				bpId: businessPartner?.id,
			})
				.then((cart) => {
					dispatch(
						setCart(
							formatCartPrices(cart, localeContext, currencyCode),
						),
					);
					setIsLoading(false);
				})
				.catch((errors) => {
					const errorsArray = [];

					errors.map((error) => {
						// ProductNotFound (600) - Http: 404 (Not Found)
						if (error.code === 600) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaProducts;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotFound;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						if (error.code === 403) {
							// ProductNotFoundInLN (403) - Http: 404 (Not Found)
							if (error.name === 'ProductNotFoundInLN') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageProductNotFoundInLn;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in top of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							// ProductDiscontinuedInLN (403) - Http: 404 (Not Found)
							if (error.name === 'ProductDiscontinuedInLN') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageProductIsDiscontinued;
								errorsArray.push({
									area: dictionaryArea,
									message: dictionaryMessage,
								});
							}
						}

						// ProductNotAvailableForBuyer (601) - Http: 400 (Bad Request)
						if (error.code === 601) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaProducts;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotAvailableForBuyer;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// ProductNotForSaleInLN (405) - Http: 404 (Not Found)
						if (error.code === 405) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotForSaleInLN;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// BasketItemInvalidInLN (408) - Http: 404 (Not Found)
						if (error.code === 408) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageBasketItemInvalidInLN;
							const sku = error.subjectId; // Do not delete, is actually used
							// bpId is already defined in top of function
							errorsArray.push({
								area: dictionaryArea,
								message: eval('`' + dictionaryMessage + '`'),
							});
						}

						// HazardousFeeCalculationError (203) - Http: 404 (Not found)
						if (error.code === 203) {
							if (error.area === 'External System') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHazardousFeeCalculationError1;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							if (error.area === 'Prices') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaPrices;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHazardousFeeCalculationError2;
								// basketId is already defined in start of compontent
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}
						}

						// HandlingFeeCalculationError (202) - Http: 404 (Not found)
						if (error.code === 202) {
							if (error.area === 'External System') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError1;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							if (error.area === 'Prices') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaPrices;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError2;
								// basketId is already defined in start of compontent
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}
						}
					});

					if (errorsArray.length !== 0) {
						setErrors(errorsArray);
					} else {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaExternalError;
						const dictionaryMessage =
							checkoutDictionary.errorMessageUnexpectedError;
						errorsArray.push({
							area: dictionaryArea,
							message: dictionaryMessage,
						});
						setErrors(errorsArray);
					}

					setIsLoading(false);
					return Promise.resolve(errors.response);
				});
		}
	};

	useEffect(() => {
		const bpId = businessPartner?.id;

		if (basketId) {
			setIsLoading(true);
			updateShipping(accessToken, {
				basketId,
				earliest,
				bpId: businessPartner?.id,
			})
				.then((cart) => {
					dispatch(
						setCart(
							formatCartPrices(cart, localeContext, currencyCode),
						),
					);
					setIsLoading(false);
				})
				.catch((errors) => {
					const errorsArray = [];

					errors.map((error) => {
						// UpstreamServiceUnavailable (409) - Http: 503 (Service Unavailable)
						if (error.code === 409) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaUpstreamServiceUnavailable;
							const dictionaryMessage =
								checkoutDictionary.errorMessageProductNotFound;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// LNUnavailable (400) - Http: 503 (ServiceUnavailable)
						if (error.code === 400) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageLNUnavailable;
							errorsArray.push({
								area: dictionaryArea,
								message: dictionaryMessage,
							});
						}

						// BasketItemInvalidInLN (408) - Http: 404 (Not Found)
						if (error.code === 408) {
							const dictionaryArea =
								checkoutDictionary.errorMessageAreaExternalError;
							const dictionaryMessage =
								checkoutDictionary.errorMessageBasketItemInvalidInLN;
							const sku = error.subjectId; // Do not delete, is actually used
							// bpId is already defined in top of function
							errorsArray.push({
								area: dictionaryArea,
								message: eval('`' + dictionaryMessage + '`'),
							});
						}

						// HazardousFeeCalculationError (203) - Http: 404 (Not found)
						if (error.code === 203) {
							if (error.area === 'External System') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHazardousFeeCalculationError1;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							if (error.area === 'Prices') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaPrices;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHazardousFeeCalculationError2;
								// basketId is already defined in start of compontent
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}
						}

						// HandlingFeeCalculationError (202) - Http: 404 (Not found)
						if (error.code === 202) {
							if (error.area === 'External System') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaExternalError;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError1;
								const sku = error.subjectId; // Do not delete, is actually used
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}

							if (error.area === 'Prices') {
								const dictionaryArea =
									checkoutDictionary.errorMessageAreaPrices;
								const dictionaryMessage =
									checkoutDictionary.errorMessageHandlingFeeCalculationError2;
								// basketId is already defined in start of compontent
								// bpId is already defined in start of function
								errorsArray.push({
									area: dictionaryArea,
									message: eval(
										'`' + dictionaryMessage + '`',
									),
								});
							}
						}
					});

					if (errorsArray.length !== 0) {
						setErrors(errorsArray);
					} else {
						const dictionaryArea =
							checkoutDictionary.errorMessageAreaExternalError;
						const dictionaryMessage =
							checkoutDictionary.errorMessageUnexpectedError;
						errorsArray.push({
							area: dictionaryArea,
							message: dictionaryMessage,
						});
						setErrors(errorsArray);
					}

					setIsLoading(false);
					return Promise.resolve(errors.response);

					// throw new Error('Basket did not update', e);
				});
		}
	}, [basketId, businessPartner?.id, dispatch]);

	//todo could this be refactored into some middleware?
	if (user?.role === 'Requester' || user?.role === 'Dealer Requester') {
		return (
			<Container width="Standard">
				<h1>
					{dictionary.getValue(
						'Authentication.NotAuthorized',
						null,
						'Authentication.NotAuthorized',
					)}
				</h1>
			</Container>
		);
	}
	if (!selectedCartProducts?.length) {
		return (
			<Container
				width="Standard"
				className="u-bottom-padding--xxl u-top-padding--xxl"
			>
				<h1>
					{dictionary.getValue(
						'Basket.NoProductsInBasket',
						null,
						'You have no products in basket',
					)}
				</h1>
			</Container>
		);
	}

	return (
		<>
			<StepNavigation
				steps={steps}
				handleProceed={handleProceed}
				currentStep={0}
				heading={dictionary.getValue(
					'Checkout.Heading',
					null,
					'Checkout',
				)}
				isProceedDisabled={errors.length > 0}
			/>
			<ProductsInCart
				dictionary={checkoutDictionary}
				products={selectedCartProducts.map((p) =>
					formatProductPrices(p, localeContext, currencyCode),
				)}
				costDetails={{
					costs: cDetails,
					total: {
						...cTotal,
						formattedTotal: localeContext.formatPrice(
							cTotal.totalPrice,
							currencyCode,
						),
					},
				}}
				isBasketView={false}
				handleProceed={handleProceed}
				shippingUpdated={shippingUpdated}
				showShipping={true}
				earliestShipping={earliest}
				deliveryInformation={delivery}
				currency={currencyCode}
				errors={errors}
				shippingAddress={businessPartner.addresses.find(address => address.key === "shipTo").value}
			/>
			{isLoading && <PageSpinner size="large" />}
		</>
	);
};

import { Locale } from 'application/adapters/context/LocaleContext';

export default function formatOrderLinePrices(
	orderLine: Models.OrderLine,
	localeContext: Partial<Locale>,
	currencyCode: string,
) {
	return {
		...orderLine,
		expectedShippingDate: localeContext.formatStringDate(
			orderLine?.expectedShippingDate,
		),
		price: {
			formattedPrice: localeContext.formatPrice(
				orderLine.netPrice,
				currencyCode,
			),
			formattedTotal: localeContext.formatPrice(
				orderLine.netPrice * orderLine.quantity,
				currencyCode,
			),
		},
	};
}

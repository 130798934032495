import { Meta } from './Meta';
import { HeaderFeature } from '../Header';
import { FooterFeature } from '../Footer';
import { PageContentResponse } from 'application/repositories/pageContentRepository';

export const LayoutFeature: React.FC<PageContentResponse> = ({
	children,
	page,
	culture,
	footer,
	contactLink,
	some,
	accessToken,
	root,
	metaData,
	...props
}) => {
	function getBasketLink() {
		if (root?.properties?.siteSettings) {
			for (let i = 0; i < root.properties.siteSettings.length; i++) {
				const siteSetting = root.properties.siteSettings[i];
				if (siteSetting?.content?.properties['link']?.url) {
					return siteSetting.content.properties['link'].url;
				}
			}
		}
	}

	return (
		<>
			<Meta
				{...page?.properties}
				title={metaData?.title || page?.properties?.title}
				description={
					metaData?.description || page?.properties?.description
				}
				customPageUrls={metaData?.customPageUrls}
			/>
			<HeaderFeature
				culture={culture}
				accessToken={accessToken}
				basketUrl={getBasketLink()}
				contactLink={contactLink?.contactLink}
			/>
			{children}
			<FooterFeature
				culture={culture}
				pageFooter={footer?.pageFooter}
				contactLink={contactLink?.contactLink}
				languages={props?.languageSelector}
				locations={props?.locations}
				socialLinks={some?.socialLinks}
			/>
		</>
	);
};

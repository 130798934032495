import axios from 'axios';
import { CONTENT_API_URL } from '../cms/constants';

export const getDashboardFromAdapter: (
	accessToken: string,
	bpId: string,
) => Promise<Models.Dashboard> = async (accessToken, bpId) => {
	const method = 'GET';
	const headers = {};
	headers['Authorization'] = `Bearer ${accessToken}`;
	const path = `Umbraco/Api/BpMember/Dashboard?bpId=${bpId}`;
	const endpointUrl = `${CONTENT_API_URL}${path}`;

	return axios({
		url: endpointUrl,
		method: method,
		maxRedirects: 0,
		headers,
	}).then((res) => {
		const { data } = res;

		if (!data) {
			return null;
		}

		return data;
	});
};

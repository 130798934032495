import { resetCart } from 'application/adapters/store/slices/cartSlice';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
	CheckoutReceipt,
	StepNavigation,
	Spinner,
	Container,
} from 'ui/components';
import { useEffect, useContext, useCallback, useState } from 'react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useRouter } from 'next/router';
import { checkoutNavigationSteps } from 'application/repositories/checkoutRepository';
import { callGetOrderState } from 'application/repositories/ecommerceRepository';
import { getOrder } from 'application/repositories/ordersRepository';
import { OrderHistoryItemProps } from 'ui/components';

interface OrderDetails extends OrderHistoryItemProps {
	// eslint-disable-next-line @typescript-eslint/ban-types
	tax?: number | null;
	shipping?: number | null;
	currency?: '' | null;
	lines?: [];
}

export const ReceiptPageFeature: React.FC<Umbraco.ReceiptPage> = ({
	content,
	accessToken,
}) => {
	const dictionary = useContext(DictionaryContext);
	const steps: Models.NavigationStep[] = checkoutNavigationSteps(dictionary);
	const dispatch = useDispatch();
	const router = useRouter();
	const { orderId } = router.query;
	const [orderState, setOrderState] = useState<Models.OrderState>();
	const [orderDetails, setOrderDetails] = useState<OrderDetails>();
	const [isLoading, setIsLoading] = useState<boolean>(true);

	// Information for GA4 purchase event
	const culture = useSelector(currentCulture);
	const purchaseEvent: Models.GAPurchaseEvent = {
		transaction_id: '' || null,
		value: 0 || null,
		tax: 0 || null,
		shipping: 0 || null,
		currency: '' || null,
		items: [],
	};

	const getOrderInfo = useCallback(async () => {
		const order = await callGetOrderState(accessToken, orderId);
		const orderDetails = await getOrder(
			accessToken,
			order?.orderNumber,
			culture,
			true, // addAnalyticsData
		);

		// Displaying Orders information
		setOrderState(order);
		// Setting data for GA4
		setOrderDetails(orderDetails);
	}, [accessToken, orderId]);

	useEffect(() => {
		getOrderInfo();
	}, []);

	// Send data to GA4 behind the scene
	useEffect(() => {
		const transactionId =
			orderState?.transactionId === ''
				? orderState?.orderNumber
				: orderState?.transactionId;

		// Attach data to purchaseEvent
		const orderedItems = orderDetails?.lines.map(
			(item: Record<string, any>): Models.GAPurchaseEventItem => {
				const discount = Number(
					(item.netPrice - item.grossPrice).toFixed(2),
				);
				return {
					item_id: item.itemNo,
					item_name: item.productName,
					discount: discount,
					item_category: item.itemCategory1,
					item_category2: item.itemCategory2,
					price: item.price.price,
					quantity: item.quantity,
				};
			},
		);

		purchaseEvent.currency = orderDetails?.currency;
		purchaseEvent.transaction_id = transactionId;
		purchaseEvent.value = orderDetails?.totalPrice;
		purchaseEvent.shipping = orderDetails?.shipping;
		purchaseEvent.tax = orderDetails?.tax;
		purchaseEvent.items = orderedItems;

		// Post GA purchase event when order is present
		if (orderState && orderDetails) {
			if (typeof window !== 'undefined') {
				window.dataLayer.push({
					event: 'purchase',
					ecommerce: purchaseEvent,
				});
			}
			setIsLoading(false);
		}

		// Reset cart

		dispatch(resetCart());

		// Safe to add dispatch to the
		// dependencies array https://react-redux.js.org/api/hooks#usedispatch
	}, [orderDetails, orderState, dispatch]);

	return (
		<>
			<StepNavigation
				steps={steps}
				currentStep={3}
				heading={dictionary.getValue(
					'Receipt.Heading',
					null,
					'Receipt',
				)}
			/>
			{isLoading && (
				<Container width='Standard'>
					<div style={{textAlign: "center", marginBottom: "var(--spacing--sm)"}}>
						<Spinner />
					</div>
					<p style={{textAlign: "center", marginBottom: "var(--spacing--lg)"}}>
						{dictionary.getValue(
							'Receipt.WaitingMessage',
							null,
							'We are working on your order. It may take up to a minute. You can close your browser now, if you like. An email will be sent when the order is processed.',
						)}
					</p>
				</Container>
			)}
			{orderState && (
				<CheckoutReceipt
					orderNumber={orderState.orderNumber}
					orderNumberLabel={content.properties.orderNumberLabel}
					orderStatus={orderState.orderStatus}
					orderStatusLabel={content.properties.orderStatusLabel}
					orderStatusBlocked={orderState.orderStatus === 'Blocked'}
					orderStatusNotice={
						content.properties.orderStatusBlockedNotice
					}
					reviewOrderLinkUrl={`/my-account/order-history/${orderState.orderNumber}`}
					reviewOrderLinkText={content.properties.reviewOrderLinkText}
					orderHistoryLinkUrl={'/my-account/order-history'}
					orderHistoryLinkText={
						content.properties.orderHistoryLinkText
					}
					additionalLinks={content.properties.additionalLinks}
					{...content.properties}
				/>
			)}
		</>
	);
};

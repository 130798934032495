import { useContext } from 'react';
import Head from 'next/head';
import { CookieConsent } from './CookieConsent';
import { OpenGraph } from './OpenGraph';
import { GoogleTagManager } from './GoogleTagManager';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { PageContext } from 'application/adapters/context/PageContext';
import { PageContentResponse } from 'application/repositories/pageContentRepository';
import { useRouter } from 'next/router';
import { LocaleContext } from 'application/adapters/context/LocaleContext';

export const Meta: React.FC<PageContentResponse['page']['properties']> = ({
	title,
	noFollow,
	noIndex,
	description,
	customPageUrls,
}) => {
	const router = useRouter();
	const siteContext = useContext(SiteContext);
	const pageContext = useContext(PageContext);
	const localeContext = useContext(LocaleContext);
	let languageVariantUrls = pageContext?.languageVariantUrls;
	const openGraph = pageContext?.getSettings('openGraph');
	const googleTagManager = siteContext.getSettings('googleTagManager');
	const language = localeContext.getLocaleLanguage();

	if (customPageUrls) {
		const currentUrlPath = router.asPath;
		languageVariantUrls = languageVariantUrls.map((url) => ({
			...url,
			text: `${url.text}${currentUrlPath}`,
		}));
	}
	const canonicalUrl = languageVariantUrls.find(
		(url) => url.culture === language,
	);
	return (
		<>
			<Head>
				<title>{title ? title : pageContext.name}</title>
				<meta
					name="robots"
					content={`${noFollow ? 'nofollow' : 'follow'}, ${
						noIndex ? 'noindex' : 'index'
					}`}
				/>
				<meta name="description" content={description} />
				<link rel="canonical" href={canonicalUrl?.text} />
				{languageVariantUrls
					?.filter((url) => url.culture != language)
					.map((url) => (
						<link
							key={url.culture}
							rel="alternate"
							hrefLang={url.culture}
							href={url.text}
						/>
					))}
			</Head>
			<CookieConsent culture={siteContext?.culture} />
			{openGraph && (
				<OpenGraph
					{...openGraph}
					pageName={pageContext.name}
					pageUrl={pageContext.url}
				/>
			)}
			{googleTagManager && <GoogleTagManager {...googleTagManager} />}
		</>
	);
};

import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import axios from 'axios';
import { signOut } from 'next-auth/react';
import { useSelector } from 'react-redux';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { Dashboard } from 'ui/components/3-organisms/Dashboard';
import { Link } from 'ui/components';
import { getDashboard } from 'application/repositories/dashboardRepository';

export const DashboardFeature: React.FC<Umbraco.Dashboard> = ({
	content,
	accessToken,
}) => {
	const { links, image } = content?.properties ?? {};
	const authentication = useAuthenticationContext();
	const router = useRouter();
	const businessPartner = useSelector(currentCustomer);
	const dictionary = useContext(DictionaryContext);
	const [dashboard, setDashboard] = useState({} as Models.Dashboard);

	useEffect(() => {
		getDashboard(accessToken, businessPartner?.id).then((res) => {
			if (!businessPartner?.id) {
				res.organization = (
					<Link
						url={`/my-account/delivery-addresses?returnUrl=${encodeURIComponent(
							router.asPath,
						)}`}
					>
						{dictionary.getValue(
							'MyAccount.MissingCustomerProfileMessage',
							null,
							'No customer profile selected. Click here to choose one.',
						)}
					</Link>
				);
			}

			setDashboard(res);
		});
	}, []);

	const allLinks = [
		{
			url: '/my-account/delivery-addresses',
			name: dictionary.getValue(
				'DeliveryAddresses',
				null,
				'Delivery addresses',
			),
		},
		{
			url: '/my-account/order-history',
			name: dictionary.getValue('OrderHistory', null, 'Order history'),
		},
		...(links ?? []),
	];

	// To be enabled in a later iteration
	// const onFirstButtonClick = () => {
	// 	router.push('/my-account/');
	// };

	const onSecondButtonClick = async () => {
		signOut({
			redirect: false,
		}).then(() => {
			router.push(
				`https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${location.origin}`,
			);
		});
	};

	return (
		<Dashboard
			name={authentication?.user?.name.split(' ')[0]}
			company={dashboard?.organization}
			contacts={dashboard?.contacts}
			image={image}
			links={allLinks}
			accountLink="/my-account/"
			// onFirstButtonClick={onFirstButtonClick} // To be enabled in a later iteration
			onSecondButtonClick={onSecondButtonClick}
		/>
	);
};

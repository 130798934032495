import { CategoryList } from 'ui/components/3-organisms/CategoryList';
import { getCategories } from 'application/repositories/ecommerceRepository';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';

export const CategoryListFeature = ({ content, accessToken }) => {
	const [categoryData, setCategoryData] = useState<any>();
	const culture = useSelector(currentCulture);
	const businessPartner = useSelector(currentCustomer);

	const fetchCategories = async () => {
		await getCategories(accessToken, culture, businessPartner?.id).then(
			(res) => setCategoryData(res),
		);
	};

	useEffect(() => {
		fetchCategories();
	}, [businessPartner?.id]);

	return <>{categoryData && <CategoryList {...categoryData} />}</>;
};

import { PaginationProps, ProductList, SortingTypes } from 'ui/components';
import {
	useState,
	useEffect,
	useContext,
	useCallback,
	useMemo,
	useRef,
} from 'react';
import {
	getProductsByCategory,
	updateBasket,
} from 'application/repositories/ecommerceRepository';
import {
	selection,
	facets,
	facetsChanged,
	categories,
} from 'application/adapters/store/slices/categorySlice';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import {
	currency,
	currentCartId,
	setCart,
} from 'application/adapters/store/slices/cartSlice';
import cultureSlice, {
	currentCulture,
} from 'application/adapters/store/slices/cultureSlice';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';
import { ProductSearchList } from 'ui/components/3-organisms/ProductSearchList';
import { PaginationWithButtonsProps } from 'ui/components/2-molecules/Navigation/Pagination/PaginationWithButtons';

export const ProductListFeature: unknown = ({ accessToken }) => {
	const selectiondata = useSelector(selection);
	const facetsData = useSelector(facets);
	const categoriesData = useSelector(categories);
	const router = useRouter();
	const [productsByCategoryData, setProductsByCategoryData] =
		useState<{ products: Models.Product[] }>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [facetState, setFacetState] =
		useState<{ [key: string]: { [key: string]: boolean } }>(null);
	const [prevSelection, setPrevSelection] = useState<string>(null);
	const dispatch = useDispatch();
	const { status } = useSession();
	const dictionary = useContext(DictionaryContext);
	const businessPartner = useSelector(currentCustomer);
	const basketId = useSelector(currentCartId);
	const culture = useSelector(currentCulture);
	const localeContext = useContext(LocaleContext);
	const currencyCode = useSelector(currency);
	const [paginationData, setPaginationData] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);
	const [prevPage, setPrevPage] = useState(0);
	const [prevBpId, setPrevBpId] = useState(null);
	const paginationDict = {
		first: dictionary.getValue('Pagination.First', null, 'First'),
		last: dictionary.getValue('Pagination.Last', null, 'Last'),
		previous: dictionary.getValue('Pagination.Previous', null, 'Previous'),
		next: dictionary.getValue('Pagination.Next', null, 'Next'),
	};
	const listRef = useRef(null);
	const onPageChange = (event, page) => {
		event.preventDefault();

		setCurrentPage(page);
		window.scrollTo(0, 0);
	};

	const paginationDefaultData: PaginationWithButtonsProps = {
		controlFirst: {
			direction: 'prev',
			label: paginationDict.first,
			ariaLabel: paginationDict.first,
		},
		controlLast: {
			direction: 'next',
			label: paginationDict.last,
			ariaLabel: paginationDict.last,
		},
		controlPrev: {
			direction: 'prev',
			label: paginationDict.previous,
			ariaLabel: paginationDict.previous,
		},
		controlNext: {
			direction: 'next',
			label: paginationDict.next,
			ariaLabel: paginationDict.next,
		},
		paginationItems: [],
		onPaginationElementClick: onPageChange,
	};
	const toFacets = (facets) => {
		if (facets) {
			const facetObject = {};
			const facetNames = Object.keys(facets);
			for (let i = 0; i < facetNames.length; i++) {
				const facetName = facetNames[i];
				const facetValueObject = facets[facetName];
				if (facetValueObject) {
					const facetValueObjectKeys = Object.keys(facetValueObject);
					for (let t = 0; t < facetValueObjectKeys.length; t++) {
						const facetValueKey = facetValueObjectKeys[t];
						const facetValue = facetValueObject[facetValueKey];
						if (facetValue) {
							if (!facetObject[facetName]) {
								facetObject[facetName] = [];
							}
							facetObject[facetName].push(facetValueKey);
						}
					}
				}
			}
			return facetObject;
		}

		return null;
	};
	const pageSize = 30;
	function setDefaultFacetState(res) {
		const facetValuesState = {};
		for (let i = 0; i < res.facets.length; i++) {
			const facet = res.facets[i];
			facetValuesState[facet.name] = {};
			for (let t = 0; t < facet.facetvalues.length; t++) {
				const facetValue = facet.facetvalues[t];
				facetValuesState[facet.name][facetValue.value] = false;
			}
		}
		setFacetState(facetValuesState);
	}

	function updateFacetState(res) {
		setFacetState((prevState) => {
			const facetNames = Object.keys(prevState);
			for (let i = 0; i < facetNames.length; i++) {
				let found = false;
				let facetValuesRes = null;
				for (let t = 0; t < res.length; t++) {
					if (res[t].name == facetNames[i]) {
						found = true;
						facetValuesRes = res[t].facetvalues;
					}
				}
				if (found) {
					const facetValues = Object.keys(prevState[facetNames[i]]);
					for (let t = 0; t < facetValues.length; t++) {
						let foundFacetValue = false;
						for (let x = 0; x < facetValuesRes.length; x++) {
							if (facetValuesRes[x].value == facetValues[t]) {
								foundFacetValue = true;
							}
						}
						if (!foundFacetValue) {
							prevState[facetNames[i]][facetValues[t]] = false;
						}
					}
				} else {
					const facetValues = Object.keys(prevState[facetNames[i]]);
					for (let t = 0; t < facetValues.length; t++) {
						prevState[facetNames[i]][facetValues[t]] = false;
					}
				}
			}
			return prevState;
		});
	}

	async function setFacetData() {
		setIsLoading(true);
		await getProductsByCategory(
			selectiondata.id,
			toFacets(facetState),
			router.locale,
			accessToken,
			businessPartner?.id,
			pageSize,
			currentPage,
		)
			.then((res) => {
				setProductsByCategoryData(res);
				dispatch(facetsChanged(res.facets));
				handleSetPaginationData(res);
				setIsLoading(false);
				updateFacetState(res.facets);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
			});
	}

	const handleFacetsChanged = async (name: string, value: string) => {
		setFacetState((previousState) => {
			previousState[name][value] = !previousState[name][value];
			setFacetData();
			return previousState;
		});
	};

	const callFetch = useCallback(async () => {
		async function fetchProductList() {
			if (selectiondata) {
				await getProductsByCategory(
					selectiondata.id,
					null,
					router.locale,
					accessToken,
					businessPartner?.id,
					pageSize,
					currentPage,
				).then((res) => {
					setProductsByCategoryData(res);
					dispatch(facetsChanged(res.facets));
					handleSetPaginationData(res);
					setIsLoading(false);
					setDefaultFacetState(res);
				});
			}
		}
		fetchProductList();
	}, [
		businessPartner?.id,
		selectiondata,
		prevSelection,
		dispatch,
		categoriesData,
		currentPage,
	]);

	useEffect(() => {
		if (selectiondata || categoriesData) {
			if (
				!prevSelection ||
				selectiondata?.id != prevSelection ||
				prevPage != currentPage ||
				prevBpId != businessPartner?.id
			) {
				setPrevPage(currentPage);
				setPrevBpId(businessPartner?.id);
				setPrevSelection(selectiondata?.id);
				callFetch();
			}
		}
	}, [
		selectiondata,
		prevSelection,
		dispatch,
		categoriesData,
		currentPage,
		businessPartner?.id,
	]);

	const handleAddProductToCart = (cartProduct) => {
		setIsLoading(true);
		updateBasket(
			accessToken,
			{
				basketId,
				cultureCode: culture,
				bpId: businessPartner?.id,
				...cartProduct,
			},
			false,
			true,
		)
			.then((res) => {
				dispatch(
					setCart(formatCartPrices(res, localeContext, currencyCode)),
				);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
				alert('Error adding product to cart');
			});
	};

	const productListDictionary = {
		searchSortBy: dictionary.getValue('Search.SortBy', null, 'Sort by:'),
	};

	const handleSetPaginationData = (res) => {
		const pagination = { ...paginationDefaultData };
		const paginationItems = [];
		for (let i = 0; i < res.totalPageCount; i++) {
			paginationItems.push({
				label: i + 1,
				active: i == currentPage,
			});
		}

		setPaginationData({ ...pagination, paginationItems });
	};

	return (
		<>
			{productsByCategoryData && (
				<>
					<ProductList
						isLoggedIn={status === 'authenticated'}
						products={productsByCategoryData.products?.map((p) => ({
							...p,
							price: {
								...p.price,
								formattedPrice: localeContext.formatPrice(
									p.price?.price,
									p.price?.currencyCode,
								),
							},
						}))}
						numberOfProducts={
							productsByCategoryData.products.length
						}
						sortingSettings={[
							{
								parameter: SortingTypes.NAME,
								text: dictionary.getValue(
									'Product.Sorting.ProductName',
									null,
									'Product name',
								),
							},
							{
								parameter: SortingTypes.PRICEMIN,
								text: dictionary.getValue(
									'Product.Sorting.PriceLowToHigh',
									null,
									'Price (low to high)',
								),
							},
							{
								parameter: SortingTypes.PRICEMAX,
								text: dictionary.getValue(
									'Product.Sorting.PriceHighToLow',
									null,
									'Price (high to low)',
								),
							},
						]}
						facets={facetsData}
						handleFacetsChanged={handleFacetsChanged}
						isLoadStory={isLoading}
						selectionId={selectiondata?.id}
						handleAddProductToCart={handleAddProductToCart}
						hasBp={businessPartner?.id ? true : false}
						dictionary={productListDictionary}
						paginationData={paginationData}
					/>
				</>
			)}
		</>
	);
};

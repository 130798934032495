type Dictionary = {
	getValue?: (...args: any) => string;
};

export const checkoutNavigationSteps = (dictionary: Dictionary) => {
	const steps: Models.NavigationStep[] = [
		{
			stepTitle: dictionary.getValue(
				'Checkout.Steps.Delivery',
				null,
				'Delivery',
			),
			step: 0,
		},
		{
			stepTitle: dictionary.getValue(
				'Checkout.Steps.Information',
				null,
				'Information',
			),
			step: 1,
		},
		{
			stepTitle: dictionary.getValue(
				'Checkout.Steps.Payment',
				null,
				'Payment',
			),
			step: 2,
		},
		{
			stepTitle: dictionary.getValue(
				'Checkout.Steps.Confirmation',
				null,
				'Confirmation',
			),
			step: 3,
		},
	];

	return steps;
};

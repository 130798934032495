import classNames from 'classnames';
import styles from './LocalNavigation.module.scss';
import { Link } from 'ui/components';

export interface LocalNavigationProps {
	className?: string;
	localNavigationItems?: SimpleNavigationItem[];
	children?: React.ReactNode[];
}

export interface SimpleNavigationItem {
	title?: string;
	id?: string;
}

export const LocalNavigation: React.FC<LocalNavigationProps> = ({
	className,
	localNavigationItems,
	children,
}) => {
	return (
		<aside className={classNames(styles.LocalNavigation, className)}>
			<ul className={styles.LocalNavigation_itemWrapper}>
				{localNavigationItems &&
					localNavigationItems.map((localNavigationItem, index) => (
						<li key={index} className={styles.LocalNavigation_item}>
							<Link url={'#' + localNavigationItem.id}>
								{localNavigationItem.title}
							</Link>
						</li>
					))}
				{children?.map((child, idx) => (
					<li key={idx} className={styles.LocalNavigation_item}>
						{child}
					</li>
				))}
			</ul>
		</aside>
	);
};

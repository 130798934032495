import classNames from 'classnames';
import styles from './CategoryList.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading';
import { Link, LinkProps } from 'ui/components/1-atoms/Action/Link';
import { Container } from 'ui/components/4-habitats';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { useContext } from 'react';

export interface CategoryListProps {
	className?: string;
	categoryListItems?: {
		link: LinkProps;
		categoryNumber: number;
	}[];
}

export const CategoryList: React.FC<CategoryListProps> = ({
	className,
	categoryListItems,
}) => {
	const dictionary = useContext(DictionaryContext);
	return (
		<Container width="Standard">
			<section className={classNames(styles.CategoryList, className)}>
				<Heading
					headingLevel="h2"
					style="xs"
					className={styles.CategoryList_heading}
				>
					{dictionary.getValue(
						'Category.Explore',
						null,
						'Explore our categories',
					)}
				</Heading>

				<ul className={classNames(styles.CategoryList_list, className)}>
					{categoryListItems &&
						categoryListItems.map((categoryListItem, index) => (
							<li
								key={index}
								className={styles.CategoryList_item}
							>
								<Link
									className={styles.CategoryList_link}
									{...categoryListItem?.link}
								>
									{categoryListItem?.link.children}
								</Link>
								<span className={styles.CategoryList_number}>
									({categoryListItem?.categoryNumber})
								</span>
							</li>
						))}
				</ul>
			</section>
		</Container>
	);
};

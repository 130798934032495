import { Locale } from 'application/adapters/context/LocaleContext';

export default function formatProductPrices(product: Models.CartProduct, localeContext: Partial<Locale>, currencyCode: string) {
	return {
		...product,
		shipments: product.shipments.map((s) => ({
			...s,
			formattedEarliestDeliveryDate: localeContext.formatStringDate(
				s.earliestDeliveryDate,
			),
		})),
		price: {
			...product.price,
			formattedPrice: localeContext.formatPrice(
				product.price.price,
				product.price.currency,
			),
			formattedTotal: localeContext.formatPrice(
				product.price.total,
				product.price.currency,
			),
			formattedUnitPriceBeforeDiscount: localeContext.formatPrice(
				product.price.unitPriceBeforeDiscount,
				product.price.currency,
			),
		},
	};
}

import { Container, Link } from 'ui/components';

export const LinkFeature: React.FC<Umbraco.LinkFeature> = ({ content }) => (
	<Container
		width={content.properties.containerWidth || 'Small'}
		className="u-top-margin--lg u-bottom-margin--xxl"
	>
		<Link {...content.properties.linkUrl}>
			{content.properties.linkUrl.name}
		</Link>
	</Container>
);
import { Header } from 'ui/components/4-habitats/Header';
import { useNavigationContext } from 'application/adapters/context/NavigationContext';
import { SiteContext } from 'application/adapters/context/SiteContext';
import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
	currency,
	currentCartId,
	resetCart,
	setCart,
} from 'application/adapters/store/slices/cartSlice';
import {
	currentCustomer,
	setCustomer,
} from 'application/adapters/store/slices/customerSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getUserBasket } from 'application/repositories/ecommerceRepository';
import { signIn, signOut } from 'next-auth/react';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { getBusinessPartners } from 'application/repositories/businessPartnersRepository';
import { setCulture } from 'application/adapters/store/slices/cultureSlice';
import { setCookie } from 'cookies-next';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';
interface HeaderFeatureProps {
	accessToken?: any;
	basketUrl: string;
	culture: string;
	contactLink: Umbraco.Link;
}

export const HeaderFeature: React.FC<HeaderFeatureProps> = ({
	accessToken,
	basketUrl,
	culture,
	contactLink,
}) => {
	const dictionary = useContext(DictionaryContext);
	const navContext = useNavigationContext();
	useContext(SiteContext);
	const router = useRouter();
	const authentication = useAuthenticationContext();
	const navigationItems = navContext?.getNavigationVisible().decendants;
	const serviceNavigationItems =
		navContext?.getNavigationVisible({
			byName: 'service',
		}).decendants ?? [];
	const dispatch = useDispatch();
	const businessPartner = useSelector(currentCustomer);
	const href = `/${router.locale}`;
	const localeContext = useContext(LocaleContext);
	const currencyCode = useSelector(currency);
	const viewCartDisabledPaths = [
		'/delivery',
		'/information',
		'/payment',
		'/receipt',
	];
	const enableViewCart = !viewCartDisabledPaths.includes(
		router.asPath.toLowerCase(),
	);

	useEffect(() => {
		const bpId = businessPartner?.id;

		if (accessToken && bpId && culture) {
			getUserBasket(accessToken, culture, bpId).then((res) => {
				if (res) {
					dispatch(
						setCart(formatCartPrices(res, localeContext, currencyCode)),
					);
				}
			});
		} else {
			dispatch(resetCart());
		}
	}, [accessToken, businessPartner, culture, dispatch]);

	useEffect(() => {
		dispatch(setCulture(culture));
	}, [culture, dispatch]);

	// '/' as path makes sure you get to /en-US if you're on any path on en-US and likewise with da-DK etc. the href is just what's on the a-tag on the logo, though not being used.
	// If you put a '/' as href on the logo it'll show it as linking to / but actually link to /en-US, /da-DK or whatever
	const path = '/';
	function logoClicked(e): void {
		e.preventDefault();
		router.push(path);
	}

	// Fetch businessPartners if user is authenticated but no businessPartner is set
	useEffect(() => {
		if (accessToken && !businessPartner?.id) {
			getBusinessPartners(accessToken)
				.then((res) => {
					// Pre-select businessPartner if there's only one
					let countryCode = 'DK'; // fallback to DK if no country on bp
					if (res?.length === 1) {
						const customer = res[0];
						dispatch(setCustomer(customer));
						setCookie('bpId', customer.id);
						countryCode = customer.addresses
							?.find((address) => address.key === 'shipTo')
							?.value.country.trim();
					}
					if (!localeContext.getLocaleCountry()) {
						localeContext.setLocaleCountry(countryCode);
					}
				})
				.catch((err) => {
					console.error(err);
				});
		} else if (!accessToken) {
			dispatch(setCustomer(null));
			setCookie('bpId', '');
		}
	}, [accessToken]);

	// Search
	const handleSearchSubmit = (query: string) => {
		const locale = router.locale ? `${router.locale}/` : '';
		router.push(`/${locale}search?query=${query}&page=1`);
	};

	async function handleSignOut() {
		signOut({
			redirect: false,
		}).then(() => {
			const pagesToRedirectToHome = [
				'/my-account',
				'/basket',
				'/delivery',
				'/information',
				'/payment',
				'/receipt',
			];
			const redirectToHome = pagesToRedirectToHome.reduce(
				(acc, el) => acc || router.asPath.startsWith(el),
				false,
			);
			const returnUrl = redirectToHome ? location.origin : location.href;
			router.push(
				`https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/account/logout?returnUrl=${returnUrl}`,
			);
		});
	}

	const handleGoToCheckout = basketUrl
		? () => {
				router.push(basketUrl);
		  }
		: null;

	const onNavigateToUserProfile = () => {
		router.push('/my-account'); //todo - make this dynamic? or use the user profile page from the site config?
	};

	const loginStatusDictionary = {
		firstButtonText: dictionary.getValue(
			'LoginStatus.FirstButtonText',
			null,
			'User details',
		),
		secondButtonText: dictionary.getValue(
			'LoginStatus.SecondButtonText',
			null,
			'Logout',
		),
		loggedInAs: dictionary.getValue(
			'LoginStatus.LoggedInAs',
			null,
			'Logged in as',
		),
		registeredAddresses: dictionary.getValue(
			'LoginStatus.RegisteredAddresses',
			null,
			'Your registered addresses',
		),
		soldToAddress: dictionary.getValue(
			'LoginStatus.SoldToAddress',
			null,
			'Sold to address',
		),
		shippingAddress: dictionary.getValue(
			'LoginStatus.ShippingAddress',
			null,
			'Shipping address',
		),
		invoiceAddress: dictionary.getValue(
			'LoginStatus.InvoiceAddress',
			null,
			'Invoice address',
		),
		missingCustomerProfileMessage: dictionary.getValue(
			'MyAccount.MissingCustomerProfileMessage',
			null,
			'No customer profile selected. Click here to choose one.',
		),
		missingAddressesUrl: '/my-account/delivery-addresses',
		links: dictionary.getValue('LoginStatus.Links', null, 'Links'),
		change: dictionary.getValue('Action.Change', null, 'Change'),
		customerDetails: dictionary.getValue(
			'LoginStatus.CustomerDetails',
			null,
			'Customer details',
		),
		changeCallAccount: dictionary.getValue(
			'LoginStatus.ChangeCallAccount',
			null,
			'Change call account',
		),
		soldTo: dictionary.getValue('Action.SoldTo', null, 'Sold to'),
		shipTo: dictionary.getValue('Action.ShipTo', null, 'Ship to'),
		invoiceTo: dictionary.getValue('Action.InvoiceTo', null, 'Invoice to'),
	};

	const loginStatusLinks = [
		{
			title: dictionary.getValue('MyAccount', null, 'My account'),
			url: '/my-account',
		},
		{
			title: dictionary.getValue(
				'DeliveryAddresses',
				null,
				'Delivery addresses',
			),
			url: '/my-account/delivery-addresses',
		},
		{
			title: dictionary.getValue('OrderHistory', null, 'Order history'),
			url: '/my-account/order-history',
		},
	] as Models.NavigationItem[];

	/* Contact form */

	// Disabled until Struers is ready to replace their old contact form:
	// const contactFormLinkText = dictionary.getValue(
	// 	'ContactForm.LinkText',
	// 	null,
	// 	'Contact',
	// );

	// Temporary contactFormLinkText until Struers is ready to replace their old contact form:
	const contactFormLinkText = contactLink?.name;

	if (
		contactFormLinkText &&
		serviceNavigationItems.findIndex(
			(item) => item.name === contactFormLinkText,
		) === -1
	) {
		serviceNavigationItems.push({
			name: contactFormLinkText,

			// Disabled until Struers is ready to replace their old contact form:
			// url: '#' + encodeURIComponent(contactFormLinkText.replace(' ', '')),

			// Temporary solution until Struers is ready to replace their old contact form:
			url: contactLink?.url,

			// Open url in new tab. Must be removed once the new contact form is in use.
			target: '_blank',
		});
	}

	const signupFormLinkText = !accessToken
		? dictionary.getValue('SignupForm.LinkText', null, 'Request Account')
		: null;

	const regex = /\s/gi;
	const signupFormLinkTextNormalized = encodeURIComponent(
		signupFormLinkText?.replace(regex, ''),
	);

	if (
		signupFormLinkText &&
		serviceNavigationItems.findIndex(
			(item) => item.name === signupFormLinkText,
		) === -1
	) {
		serviceNavigationItems.push({
			name: signupFormLinkText,
			url: '#' + signupFormLinkTextNormalized,
		});
	}

	const shoppingCartText = dictionary.getValue(
		'ShoppingCart.ShoppingCartText',
		null,
		'View cart',
	);
	const headerDictionary = {
		skipToContent: dictionary.getValue(
			'Header.SkipToContent',
			null,
			'Skip to content',
		),
	};

	const avatarDictionary = {
		actionLogin: dictionary.getValue('Action.Login', null, 'Login'),
	};

	return (
		<>
			<Header
				navigationItems={navigationItems}
				serviceNavigationItems={serviceNavigationItems}
				logoClicked={logoClicked}
				href={href}
				onSignIn={() => {
					signIn('authServer');
				}}
				onSignOut={handleSignOut}
				profile={authentication?.user}
				accessToken={accessToken}
				handleSearchSubmit={handleSearchSubmit}
				handleGoToCheckout={handleGoToCheckout}
				onNavigateToUserProfile={onNavigateToUserProfile}
				loginStatusDictionary={loginStatusDictionary}
				avatarDictionary={avatarDictionary}
				addresses={businessPartner?.addresses}
				bpId={businessPartner?.id}
				links={loginStatusLinks}
				shoppingCartText={shoppingCartText}
				dictionary={headerDictionary}
				enableViewCart={enableViewCart}
			/>
		</>
	);
};

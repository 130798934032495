import classNames from 'classnames';
import styles from './AccordionNavigation.module.scss';
import { Container, Heading } from 'ui/components';
import { LocalNavigation } from '../LocalNavigation';
import { Accordion, AccordionProps } from '../Accordion/Accordion';

export interface AccordionNavigationProps {
	className?: string;
	accordionItems?: AccordionProps[];
	title?: string;
}

export const AccordionNavigation: React.FC<AccordionNavigationProps> = ({
	className,
	accordionItems,
	title,
}) => {
	return (
		<>
			<Container width="Standard">
				<Heading
					headingLevel="h1"
					style="lg"
					className={styles.AccordionNavigation_heading}
				>
					{title}
				</Heading>
				<section
					className={classNames(
						styles.AccordionNavigation,
						className,
					)}
				>
					<div>
						<LocalNavigation
							localNavigationItems={accordionItems}
						/>
					</div>

					<div>
						{accordionItems &&
							accordionItems.map((item, index) => {
								return (
									<Accordion
										title={item.title}
										accordionItems={item.accordionItems}
										id={item.id}
										key={index}
									/>
								);
							})}
					</div>
				</section>
			</Container>
		</>
	);
};

import {
	ProductSearchList,
	ProductSearchListProps,
} from 'ui/components/3-organisms/ProductSearchList';
import { searchAdapter } from 'application/adapters/search/searchAdapter';
import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { DictionaryContext } from 'application/adapters/context/Dictionary';
import { updateBasket } from 'application/repositories/ecommerceRepository';
import { PaginationProps } from 'ui/components';
import {
	currency,
	currentCartId,
	setCart,
} from 'application/adapters/store/slices/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import { currentCulture } from 'application/adapters/store/slices/cultureSlice';
import { currentCustomer } from 'application/adapters/store/slices/customerSlice';
import { useSession } from 'next-auth/react';
import { LocaleContext } from 'application/adapters/context/LocaleContext';
import formatCartPrices from 'helpers/formatCartPrices';

export const ProductSearchListFeature = ({ content, accessToken }) => {
	const router = useRouter();
	const dispatch = useDispatch();
	const dictionary = useContext(DictionaryContext);
	const basketId = useSelector(currentCartId);
	const culture = useSelector(currentCulture);
	const businessPartner = useSelector(currentCustomer);
	const { status } = useSession();
	const [isLoading, setIsLoading] = useState(false);
	const localeContext = useContext(LocaleContext);
	const currencyCode = useSelector(currency);
	const handleAddProductToCart = (cartProduct) => {
		setIsLoading(true);
		updateBasket(accessToken, {
			basketId,
			cultureCode: culture,
			bpId: businessPartner?.id,
			...cartProduct,
		})
			.then((res) => {
				dispatch(setCart(formatCartPrices(res, localeContext, currencyCode)),);
				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
				alert('Error adding product to cart');
			});
	};

	const paginationDict = {
		first: dictionary.getValue('Pagination.First', null, 'First'),
		last: dictionary.getValue('Pagination.Last', null, 'Last'),
		previous: dictionary.getValue('Pagination.Previous', null, 'Previous'),
		next: dictionary.getValue('Pagination.Next', null, 'Next'),
	};

	const paginationDefaultData: PaginationProps = {
		controlFirst: {
			direction: 'prev',
			label: paginationDict.first,
			ariaLabel: paginationDict.first,
		},
		controlLast: {
			direction: 'next',
			label: paginationDict.last,
			ariaLabel: paginationDict.last,
		},
		controlPrev: {
			direction: 'prev',
			label: paginationDict.previous,
			ariaLabel: paginationDict.previous,
		},
		controlNext: {
			direction: 'next',
			label: paginationDict.next,
			ariaLabel: paginationDict.next,
		},
		paginationItems: [],
	};

	const [searchResultData, setSearchResultData] =
		useState<ProductSearchListProps>({
			heading: dictionary.getValue(
				'Products.ProductList',
				null,
				'Product list',
			),
			columns: 14,
			numberOfProducts: null,
			searchWord: '',
			products: [],
			isLoggedIn: status === 'authenticated',
			paginationData: paginationDefaultData,
			sortOptions: null,
			handleAddProductToCart,
			hasBp: businessPartner?.id ? true : false,
		});

	let lastSearch = null;

	const [sortingDefaultValue, setSortingDefaultValue] = useState<string>('');

	useMemo(async () => {
		setIsLoading(true);
		const params = document
			? new URL(document.location as any).searchParams
			: null;
		const query = params?.get('query');
		const sorting = params?.get('sorting');
		let page = params?.get('page') as any;

		if (page) {
			page = parseInt(page);
		} else {
			page = 1;
		}

		if (
			lastSearch &&
			lastSearch.page == page &&
			lastSearch.query == query &&
			lastSearch.sorting == sorting
		)
			return;

		lastSearch = {
			query,
			page,
			sorting,
		};

		await searchAdapter({
			query,
			page,
			sorting,
			locale: router.locale,
			accessToken: accessToken,
			bpId: businessPartner?.id,
		})
			.then((res) => {
				const path = router.asPath.split('?')[0];
				const pagination = { ...paginationDefaultData };
				const paginationData = [];
				const sortingValue = sorting ? `&sorting=${sorting}` : '';
				const maxItemsPerPage = 50;

				for (let i = 0; i < res.totalCount / maxItemsPerPage; i++) {
					paginationData.push({
						link: `${path}?query=${query}&page=${
							i + 1
						}${sortingValue}`,
						label: i + 1,
						active: i + 1 == page,
					});
				}
				pagination.paginationItems = paginationData;

				if (page <= 1) {
					pagination.controlFirst.label = null;
					pagination.controlPrev.label = null;
				} else {
					pagination.controlPrev.link = `${path}?query=${query}&page=${
						page - 1
					}${sortingValue}`;
				}

				if (
					Math.floor(Number(res.totalCount) / maxItemsPerPage) + 1 ==
					page
				) {
					pagination.controlLast.label = null;
					pagination.controlNext.label = null;
				} else {
					pagination.controlNext.link = `${path}?query=${query}&page=${
						page + 1
					}${sortingValue}`;
				}

				setSearchResultData({
					heading: dictionary.getValue(
						'Products.ProductList',
						null,
						'Product list',
					),
					columns: res.products.length,
					numberOfProducts: res.totalCount,
					searchWord: query,
					products: res.products.map((p) => ({
						...p,
						price: {
							...p.price,
							formattedPrice: localeContext.formatPrice(
								p.price?.price,
								p.price?.currencyCode,
							),
						},
					})),
					isLoggedIn: status === 'authenticated',
					paginationData: pagination,
					sortOptions: res.sortOptions,
					handleAddProductToCart,
					hasBp: businessPartner?.id ? true : false,
				});

				setIsLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setIsLoading(false);
				alert('Error loading search results');
			});
	}, [router, status, sortingDefaultValue]);

	useEffect(() => {
		setSearchResultData({
			isLoggedIn: status === 'authenticated',
			...searchResultData,
		});
	}, [status, sortingDefaultValue]);

	return (
		<>
			{searchResultData && (
				<ProductSearchList
					isLoading={isLoading}
					{...searchResultData}
					sortingDefaultValue={sortingDefaultValue}
					setSortingDefaultValue={setSortingDefaultValue}
				/>
			)}
		</>
	);
};

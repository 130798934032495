import { Login } from 'ui/components';
import { signIn, signOut } from 'next-auth/react';
import { RootState } from 'application/adapters/store/store';
import { toggleProfileVisiblity } from 'application/adapters/store/slices/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthenticationContext } from 'application/contexts/AuthenticationContext';
import axios from 'axios';

export interface LoginFeatureProps {
	redirectUrl?: string;
}

export const LoginFeature: React.VFC<LoginFeatureProps> = () => {
	const dispatch = useDispatch();
	const authentication = useAuthenticationContext();

	const showProfile = useSelector(
		(state: RootState) => state.profile.showProfile,
	);

	function handleProfileVisiblity(show: boolean) {
		dispatch(toggleProfileVisiblity(show));
	}

	async function handleSignOut() {
		// await axios.get(`https://${process.env.NEXT_PUBLIC_AUTHENTICATION_DOMAIN}/Account/LogoutHeadless`).catch((error) => {
		// 	// Log but otherwise ignore error. A failed logout in the backend is not critical.
		// 	console.error(error);
		// });
		signOut();
	}

	return (
		<p></p>
		// <Login
		// 	onSignIn={signIn}
		// 	onSignOut={handleSignOut}
		// 	setProfileVisiblity={handleProfileVisiblity}
		// 	isProfileVisible={showProfile}
		// 	profile={authentication?.user}
		// />
	);
};

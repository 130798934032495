import { ApiError, ApiRedirect } from 'helpers/error';
import axios from 'axios';
import { ECOMMERCE_API_URL } from './_constants';
import { CONTENT_API_URL } from '../cms/constants';

export interface RequestInterface {
	query: string;
	page: number;
	sorting: string;
	locale: string;
	accessToken?: string;
	bpId?: string
}

//const SEARCH_API_URL = process.env.NEXT_PUBLIC_ECOMMERCE_API_URL?.endsWith('/') ? process.env.NEXT_PUBLIC_ECOMMERCE_API_URL: `${process.env.NEXT_PUBLIC_ECOMMERCE_API_URL}/`;
// TODO: Delete when environment variables work in dev again
//const SEARCH_API_URL =
//	'https://app-strub2b-cms-dev-001.azurewebsites.net/umbraco/api/search/';
const contentApiUrl = process.env.NEXT_PUBLIC_CONTENT_API_URL;

export interface SearchResultResponse {
	query: string;
	numResults: number;
	totalCount: number;
	products: Models.Product[];
	sortOptions: string[];
}

export const searchAdapter = ({
	query,
	page,
	sorting,
	locale,
	accessToken,
	bpId,
}: RequestInterface): Promise<SearchResultResponse> => {
	if (!ECOMMERCE_API_URL) {
		throw new ApiError(
			'SEARCH_API_URL:NOTDEFINED',
			500,
			'Search api url is not defined',
		);
	}

	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	return axios({
		url:
			`${ECOMMERCE_API_URL}search?query=${query}&page=${page}&locale=${locale}&bpId=${bpId}` +
			(sorting ? `&sortOption=${sorting}` : ''),
		headers: headers,
		method: 'GET',
		maxRedirects: 0,
	})
		.then((res) => {
			const { data } = res ?? {};

			if (data.products) {
				data.products = data.products.map((x) => {
					if (x?.image && !x?.image.includes('http')) {
						x.image = contentApiUrl + x.image;
					}

					return x;
				});
			}

			return data;
		})
		.catch((error) => {
			const message = error?.response?.data?.message ?? error;
			const statusCode = error?.response?.status || 500;

			if (statusCode === 301 || statusCode === 302) {
				throw new ApiRedirect(statusCode, error.response.data.url);
			}

			throw new ApiError('Search API error', statusCode, message);
		});
};

import { AccordionNavigation } from 'ui/components/3-organisms/AccordionNavigation';

export const AccordionNavigationFeature = ({ content }) => {
	const { accordionCollection, title } = content?.properties ?? {};

	const mappedAccordionItems = accordionCollection.map(({ content }) => {
		let { accordionItems, id } = content?.properties ?? {};
		const { title } = content?.properties ?? {};
		id = title ? encodeURI(title.split(' ').join('-').toLowerCase()) : '';

		accordionItems = accordionItems.map(({ content }) => {
			const { htmlContent, title } = content?.properties ?? {};
			return { text: htmlContent, title };
		});

		return {
			title,
			id,
			accordionItems,
		};
	});

	return (
		<AccordionNavigation
			accordionItems={mappedAccordionItems}
			title={title}
		/>
	);
};

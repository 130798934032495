import {
	getOrderFromAdapter,
	getOrderHistoryFromAdapter,
} from 'application/adapters/ecommerce/ordersAdapter';
import { OrderHistoryItemProps } from 'ui/components';

//addAnalyticsData is always false unless specified
export const getOrder: (
	accessToken: any,
	orderNumber: number | string | string[],
	culture: string,
	addAnalyticsData?: boolean,
) => Promise<OrderHistoryItemProps> = async (
	accessToken,
	orderNumber,
	culture,
	addAnalyticsData = false, 
) => {
		const order = await getOrderFromAdapter(
			accessToken,
			orderNumber,
			culture,
			addAnalyticsData,
		);

		if (!order) return null;

		const mappedOrderLines = order?.lines?.map((line) => {
			return {
				...line,
				price: {
					price: line.pricePerUnit,
					total: line.lineTotal,
					currency: line.currency,
				},
				name: line.productName,
				sku: line.itemNo,
				transportHazardous:
					line.transportHazardous === 'g10' ? true : false,
			};
		});

		return {
			...order,
			lines: mappedOrderLines,
			total: {
				discountTotal: order.Total?.DiscountTotal ?? null,
				orderTotal: order.Total?.OrderTotal ?? null,
				taxTotal: order.Total?.TaxTotal ?? null,
				paymentTotal: order.Total?.PaymentTotal ?? null,
				shippingTotal: order.Total?.ShippingTotal ?? null,
				subTotal: order.Total?.SubTotal ?? null,
				vat: order.Total?.Vat ?? null,
				currency: order.Total?.CurrencyCode ?? null,
			},
		};
	};

export const getOrderHistory: (
	accessToken: any,
	bpId: number | string,
	monthsBack: number | string,
) => Promise<OrderHistoryItemProps[]> = async (
	accessToken,
	bpId,
	monthsBack,
) => {
		const orders = await getOrderHistoryFromAdapter(
			accessToken,
			bpId,
			monthsBack,
		);

		if (!orders || orders.length < 1) {
			return null;
		}

		return orders.map((order) => ({
			...order,
			orderDate: order.bookingDate,
			estDeliveryDate: order.plannedDeliveryDate,
			statusText: order.orderStatus,
			statusActive: order.orderStatus === 'Closed' ? false : true,
			orderId: order.orderNumber,
			numberOfItems: order.totalQuantity,
			totalPrice: order.totalAmount,
			attPerson: order.attPerson ?? null,
			currency: order.currency ?? null,
		}));
	};
